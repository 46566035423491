<div class="ft2" *ngIf="(userData$ | async) as userData">
    <ft-main-nav [menuVisible]="false" [sideMenuVisible]="false"></ft-main-nav>
    <button *ngIf="closeButtonVisible" class="ft2-btn-close text-white" (click)="goBack()"></button>
    <ft-header [modifierClass]="'-short'">
        <div class="ft2-container">
            <h1 class="h1">{{ userData.firstName }} {{ userData.lastName }}</h1>
        </div>
    </ft-header>
    <section class="ft2-section">
        <div class="ft2-container">
            <div class="row">
                <div class="col-4 col-md-12 mb-3">
                    <p class="solid-text medium-text">User details</p>
                </div>
                <div class="col-8 col-md-12">
                    <ft-custom-editor title="Name" (save)="changeUser($event)">
                        <div content>
                            <div class="ft2-form-group">
                                <input class="ft2-input -large"
                                       type="text"
                                       placeholder="First name"
                                       name="firstName"
                                       required
                                       [ngModel]="userData.firstName">
                            </div>
                            <div class="ft2-form-group">
                                <input class="ft2-input -large"
                                       type="text"
                                       placeholder="Last name"
                                       name="lastName"
                                       required
                                       [ngModel]="userData.lastName">
                            </div>
                        </div>
                        <ft-edit-panel label="Name"
                                       [loading]="changeUserLoading$ | async"
                                       [error]="changeUserError$ | async">
                            <p>{{ userData.firstName }} {{ userData.lastName }}</p>
                        </ft-edit-panel>
                    </ft-custom-editor>
                    <ft-edit-panel label="Email" [readOnly]="true">
                        <p>{{ userData.email }}</p>
                    </ft-edit-panel>
                    <ft-custom-editor title="Profile pic" (save)="uploadProfilePicture(files)">
                        <ng-template #summary>
                            <p>
                                Your profile pic will be used to represent you to your audience as well as fellow
                                team
                                members across the platform. We recommend a photo that is at least 200 x 200px, and
                                your
                                face is clearly visible and centered in the image so it does not get cropped.
                            </p>
                        </ng-template>
                        <div content>
                            <ft-dropzone-uploader [multiple]="false" [accept]="'.jpg, .jpeg, .png, .gif'"
                                                  [imagePreview]="true"
                                                  (selected)="files = $event">
                            </ft-dropzone-uploader>
                        </div>
                        <ft-edit-panel label="Profile pic" [error]="uploadProfilePictureError$ | async"
                                       [loading]="uploadProfilePictureLoading$ | async">
                            <p class="muted-text" *ngIf="!userData.profileImageUrl">Enter the URL to your image</p>
                            <img *ngIf="userData.profileImageUrl" [src]="userData.profileImageUrl" width="80"
                                 height="80" />
                        </ft-edit-panel>
                    </ft-custom-editor>
                    <ft-edit-panel [static]="true" label="Password" [error]="changePasswordError$ | async">
                        <ft-custom-editor title="Change password" (save)="changePassword($event)">
                            <div content>
                                <div class="ft2-form-group">
                                    <div class="ft2-input-wrapper">
                                        <input class="ft2-input -large"
                                               type="password"
                                               placeholder="Current password"
                                               #currentPassword
                                               name="currentPassword"
                                               autocomplete="current-password"
                                               required
                                               ngModel>
                                        <ft-password-toggle class="ft2-input-icon"
                                                            [inputRef]="currentPassword"></ft-password-toggle>
                                    </div>
                                </div>
                                <div class="ft2-form-group">
                                    <div class="ft2-input-wrapper">
                                        <input class="ft2-input -large"
                                               type="password"
                                               placeholder="New password"
                                               #newPassword
                                               name="newPassword"
                                               ftPasswordStrength
                                               autocomplete="new-password"
                                               ngModel>
                                        <ft-password-toggle class="ft2-input-icon"
                                                            [inputRef]="newPassword"></ft-password-toggle>
                                        <ft-password-strength-checklist [inputRef]="newPassword">
                                        </ft-password-strength-checklist>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                    class="ft2-btn-dark -outline"
                                    [ftLoading]="changePasswordLoading$"
                                    [loadingText]="'Changing password'">
                                Change password
                            </button>
                        </ft-custom-editor>
                    </ft-edit-panel>
                    <ft-two-factor-auth-wizard [step]="userData.is2FAEnabled ? twoFactorAuthWizardSteps.Edit : twoFactorAuthWizardSteps.Setup"
                                               [loading$]="twoFactorSetupIsLoading$"
                                               [error]="twoFactorAuthError$ | async"
                                               [recoveryCodes]="twoFactorAuthRecoveryCodes$ | async"
                                               (opened)="onTwoFactorAuthWizardOpened($event)"
                                               (generateRecoveryCodes)="resetTwoFactorAuthRecoveryCodes()"
                                               (resetTwoFactorAuth)="onResetTwoFactorAuth()"
                                               (turnOffTwoFactorAuth)="turnOffTwoFactorAuth()"
                                               (verifyOtp)="onVerifyTwoFactorAuthOtp($event)"
                                               [twoFactorAuthSetupData]="twoFactorSetupData$ | async">
                        <ft-edit-panel label="Two-factor Authentication" [loading]="twoFactorSetupIsLoading$ | async">
                            <div>
                                {{ userData.is2FAEnabled | ftOnOffToggle }}
                            </div>
                        </ft-edit-panel>
                    </ft-two-factor-auth-wizard>
                </div>
            </div>
        </div>
    </section>
</div>
