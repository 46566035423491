import { createReducer, on } from '@ngrx/store';
import { TwoFactorAuthSetupState } from './two-factor-auth-setup.state';
import {
    resetTwoFactorAuth,
    resetTwoFactorAuthRecoveryCodes, resetTwoFactorAuthRecoveryCodesFailure, resetTwoFactorAuthRecoveryCodesSuccess,
    setupTwoFactorAuth,
    setupTwoFactorAuthFailure,
    setupTwoFactorAuthSuccess, verifyTwoFactorAuth, verifyTwoFactorAuthFailure, verifyTwoFactorAuthSuccess
} from 'account-hybrid/features/authentication/store/two-factor-auth-setup/two-factor-auth-setup.actions';

const initialState: TwoFactorAuthSetupState = {
    setup: null,
    loaded: false,
    loading: false,
    error: null,
    setupInProgress: false,
    recoveryCodes: []
};

export const twoFactorAuthSetupReducer = createReducer(
    initialState,
    on(setupTwoFactorAuth, resetTwoFactorAuth, (state) => ({
        ...state,
        loading: true,
        loaded: false,
        error: null,
        setupInProgress: true,
        setup: null,
    })),
    on(setupTwoFactorAuthSuccess, (state, { setup }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: null,
        setupInProgress: true,
        setup,
    })),
    on(setupTwoFactorAuthFailure, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
        setupInProgress: false,
        setup: null,
    })),
    on(verifyTwoFactorAuth, state => ({
        ...state,
        recoveryCodes: initialState.recoveryCodes,
        loaded: false,
        loading: true
    })),
    on(verifyTwoFactorAuthSuccess, (state, { recoveryCodes }) => ({
        ...state,
        recoveryCodes,
        loaded: true,
        loading: false
    })),
    on(verifyTwoFactorAuthFailure, (state, { error }) => ({
        ...state,
        recoveryCodes: initialState.recoveryCodes,
        error,
        loaded: false,
        loading: false
    })),
    on(resetTwoFactorAuthRecoveryCodes, state => ({
        ...state,
        recoveryCodes: initialState.recoveryCodes,
        loaded: false,
        loading: true
    })),
    on(resetTwoFactorAuthRecoveryCodesSuccess, (state, { recoveryCodes }) => ({
        ...state,
        recoveryCodes,
        loaded: true,
        loading: false
    })),
    on(resetTwoFactorAuthRecoveryCodesFailure, (state, { error }) => ({
        ...state,
        recoveryCodes: initialState.recoveryCodes,
        error,
        loaded: false,
        loading: false
    }))
);
