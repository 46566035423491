import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'ft-modal2',
    templateUrl: './modal2.component.html',
    styleUrls: ['./modal2.component.scss'],
})
export class Modal2Component implements OnInit, OnDestroy {
    _trigger: HTMLElement;
    triggerListenerFunc: EventListenerOrEventListenerObject;
    @ViewChild('modalTmpl', { static: false }) modalTmpl: TemplateRef<any>;
    @Input() public modalId = (Date.now() + Math.random() * 100).toString(10);
    @Input() title: string;
    @Input() summary: string;

    @Input() set trigger(trigger: HTMLElement) {
        this._trigger = trigger;

        if (!this.triggerListenerFunc) {
            this.triggerListenerFunc = this.onTriggerClick.bind(this);
        }

        if (trigger instanceof HTMLElement) {
            trigger.addEventListener('click', this.triggerListenerFunc);
        }
    }

    get trigger(): HTMLElement {
        return this._trigger;
    }

    dialogRef: MatDialogRef<any>;

    isOpened = false;

    @Output() closed = new EventEmitter();
    @Output() done = new EventEmitter<any>();
    @Output() opened = new EventEmitter();

    constructor(private dialog: MatDialog) {}

    ngOnInit() {}

    ngOnDestroy() {
        if (this.triggerListenerFunc) {
            this.trigger?.removeEventListener(
                'click',
                this.triggerListenerFunc
            );
            this.triggerListenerFunc = null;
        }
    }

    onTriggerClick(event: MouseEvent) {
        event.stopPropagation();

        this.open();
    }

    open() {
        this.isOpened = true;

        this.dialogRef = this.dialog.open(this.modalTmpl, {
            panelClass: 'ft2',
            id: this.modalId,
            backdropClass: '-dark',
            data: this,
        });
        this.dialogRef.afterOpened().subscribe(() => {
            this.opened.emit();
        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.closed.emit();
        });
        this.dialogRef
            .keydownEvents()
            .pipe(filter((event) => this.isOpened && event.key === 'Escape'))
            .subscribe(() => {
                this.close();
            });
    }

    close() {
        this.isOpened = false;
        this.dialogRef?.close();
        this.closed.emit();
    }

    onDone() {
        this.close();
        this.done.emit();
    }
}
