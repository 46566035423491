import {
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, NgModelGroup } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { ModalEditorWrapperComponent } from '../../modal-editor-wrapper/modal-editor-wrapper.component';

@Component({
    selector: 'ft-custom-editor',
    templateUrl: './custom-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CustomEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: CustomEditorComponent
        }
    ]
})
export class CustomEditorComponent extends ModalEditorBase implements OnInit {
    @ContentChild(NgModelGroup) formGroup;
    @ContentChildren(NgModel, { descendants: true }) controls: QueryList<NgModel>;
    @Output() onLoad = new EventEmitter<boolean>();
    @Output() editorOpened = new EventEmitter<any>();
    @Input() canSave = true;
    @Input() hasCloseButton = false;
    @ViewChild('wrapperRef', { static: false }) wrapperRef: ModalEditorWrapperComponent;
    formGroupInitialValue: any;
    controlsInitialValue: any;


    constructor() {
        super();
    }

    ngOnInit() {
        this.onLoad.emit(true);
    }

    onEditorVisible(): void {
        this.editorOpened.emit();
        if (this.formGroup) {
            this.formGroupInitialValue = this.formGroup.value;
        } else {
            this.controlsInitialValue = this.controlsValue;
        }
    }

    onCancelChanges() {
        if (this.formGroup) {
            this.formGroup.reset(this.formGroupInitialValue);
        } else {
            this.controls.forEach(control => {
                control.reset(this.controlsInitialValue[control.name]);
            });
        }
    }

    get controlsValue() {
        return this.controls.reduce((prev, control) => {
            return { ...prev, [control.name]: control.value };
        }, {});
    }

    applyChanges() {
        this.save.next(this.formGroup ? this.formGroup.value : this.controlsValue);
    }

    isInvalid(): boolean {
        if (this.formGroup) {
            return this.formGroup.invalid;
        }
        if (this.controls) {
            return this.controls.some(control => control.invalid);
        }
    }
}
