import { createReducer, on } from '@ngrx/store';
import { AuthenticationState } from './authentication.state';
import {
    changePassword,
    changePasswordFailure,
    changePasswordSuccess,
    changeUser,
    changeUserFailure,
    changeUserSuccess,
    redirectToTwoFactorAuth,
    resendUser,
    resendUserFailure,
    resendUserSuccess,
    resetPassword,
    resetPasswordFailure,
    resetPasswordSuccess,
    sendForgotPasswordEmail,
    sendForgotPasswordEmailFailure,
    sendForgotPasswordEmailSuccess,
    setForgotPasswordState,
    setSignInRedirectTo,
    setSignInState,
    signIn,
    signInFailure,
    signInSuccess,
    twoFactorAuthRecovery,
    twoFactorAuthRecoveryFailure, twoFactorAuthRecoverySuccess,
    twoFactorAuthSignIn,
    twoFactorAuthSignInFailure,
    twoFactorAuthSignInSuccess,
    uploadProfilePicture,
    uploadProfilePictureFailure,
    uploadProfilePictureSuccess,
    verifyToken,
    verifyTokenFailure,
    verifyTokenSuccess
} from './authentication.actions';
import { SignInStates } from '../../models/sign-in-states.enum';
import { SignInErrorMessages } from '../../models/sign-in-error-messages.enum';
import { ForgotPasswordStates } from '../../models/forgot-password-states.enum';
import { UserData } from '../../models/user-data';
import { getSession } from '../../../../core/authentication';
import { AuthError } from '../../models/auth-error';
import {
    setupTwoFactorAuthSuccess,
    verifyTwoFactorAuthSuccess
} from 'account-hybrid/features/authentication/store/two-factor-auth-setup/two-factor-auth-setup.actions';

const initUserData = (): UserData => {
    var session = getSession();
    if (typeof session === 'undefined' || session == null) {
        return null;
    }
    return {
        firstName: session.FirstName,
        lastName: session.LastName,
        profileImageUrl: session.ProfileImageUrl,
        email: session.Email,
        isImpersonating: session.IsImpersonating,
        is2FAEnabled: session.Is2FAEnabled,
    };
};

const initialState = {
    isSignInLoading: false,
    signInError: null,
    userSession: null,
    signInState: SignInStates.Default,
    signInRedirectTo: null,
    isForgotPasswordLoading: false,
    forgotPasswordState: ForgotPasswordStates.Default,
    forgotPasswordError: null,
    isResetPasswordLoading: false,
    resetPasswordError: null,
    changeUserError: null,
    isChangeUserLoading: false,
    isTokenVerificationLoading: false,
    tokenVerificationError: null,
    isResendUserLoading: false,
    isUserResent: false,
    resendUserError: null,
    isChangePasswordLoading: false,
    changePasswordError: null,
    userData: initUserData(),
    isUploadProfilePictureLoading: false,
    uploadProfilePictureError: null,
    twoFactorAuthRedirectTrigger: null
} as AuthenticationState;

export const authenticationReducer = createReducer(
    initialState,
    on(signIn, twoFactorAuthSignIn, twoFactorAuthRecovery, (state) => {
        return {
            ...state,
            isSignInLoading: true,
            signInError: null,
        };
    }),
    on(signInSuccess, twoFactorAuthSignInSuccess, (state, { userSession }) => {
        return {
            ...state,
            isSignInLoading: false,
            signInError: null,
            userSession,
        };
    }),
    on(signInFailure, twoFactorAuthSignInFailure, twoFactorAuthRecoveryFailure, (state, { error }) => {
        let signInState = SignInStates.Default;
        if (error.message === SignInErrorMessages.accountDisabled) {
            signInState = SignInStates.Disabled;
        }
        if (error.message === SignInErrorMessages.accountLocked) {
            signInState = SignInStates.Locked;
        }
        return {
            ...state,
            isSignInLoading: false,
            signInError: error,
            userSession: null,
            signInState,
        };
    }),
    on(twoFactorAuthRecoverySuccess, state => ({
        ...state,
        isSignInLoading: false,
        signInError: null,
        twoFactorAuthRecovered: true
    })),
    // probably something wrong with our hybrid configuration, ui router states are not available in auth effects. This is workaround.
    on(redirectToTwoFactorAuth, state => ({
        ...state,
        isSignInLoading: false,
        signInError: null,
        twoFactorAuthRedirectTrigger: !state.twoFactorAuthRedirectTrigger
    })),
    on(setSignInState, (state, { signInState }) => {
        return {
            ...state,
            signInError: null,
            signInState,
        };
    }),
    on(setSignInRedirectTo, (state, { redirectTo }) => {
        return {
            ...state,
            signInRedirectTo: redirectTo,
        };
    }),
    on(sendForgotPasswordEmail, (state) => {
        return {
            ...state,
            isForgotPasswordLoading: true,
            forgotPasswordError: null,
        };
    }),
    on(setForgotPasswordState, (state, { forgotPasswordState }) => {
        return {
            ...state,
            forgotPasswordState,
            forgotPasswordError: null,
        };
    }),
    on(sendForgotPasswordEmailFailure, (state, { error }) => {
        return {
            ...state,
            isForgotPasswordLoading: false,
            forgotPasswordError: error as AuthError,
        };
    }),
    on(sendForgotPasswordEmailSuccess, (state) => {
        return {
            ...state,
            isForgotPasswordLoading: false,
            forgotPasswordError: null,
            forgotPasswordState: ForgotPasswordStates.EmailSent,
            forgotPasswordSentNew:
                state.forgotPasswordState === ForgotPasswordStates.EmailSent,
        };
    }),
    on(resetPassword, (state) => {
        return {
            ...state,
            isResetPasswordLoading: true,
            resetPasswordError: null,
        };
    }),
    on(resetPasswordSuccess, (state, { userSession }) => {
        return {
            ...state,
            isResetPasswordLoading: false,
            resetPasswordError: null,
            userSession,
        };
    }),
    on(resetPasswordFailure, (state, { error }) => {
        return {
            ...state,
            isResetPasswordLoading: false,
            resetPasswordError: error,
        };
    }),
    on(changeUser, (state) => {
        return {
            ...state,
            isChangeUserLoading: true,
            changeUserError: null,
        };
    }),
    on(changeUserSuccess, (state, { userData }) => {
        return {
            ...state,
            isChangeUserLoading: false,
            changeUserError: null,
            userData: { ...state.userData, ...userData },
        };
    }),
    on(changeUserFailure, (state, { error }) => {
        return {
            ...state,
            isChangeUserLoading: false,
            changeUserError: error,
        };
    }),
    on(verifyToken, (state) => {
        return {
            ...state,
            tokenVerificationError: null,
            isTokenVerificationLoading: true,
        };
    }),
    on(verifyTokenSuccess, (state, { userData }) => {
        return {
            ...state,
            isTokenVerificationLoading: false,
            userData,
        };
    }),
    on(verifyTokenFailure, (state, { error }) => {
        return {
            ...state,
            isTokenVerificationLoading: false,
            tokenVerificationError: error,
        };
    }),
    on(resendUser, (state) => {
        return {
            ...state,
            resendUserError: null,
            isResendUserLoading: true,
            isUserResent: false,
        };
    }),
    on(resendUserSuccess, (state) => {
        return {
            ...state,
            isResendUserLoading: false,
            isUserResent: true,
        };
    }),
    on(resendUserFailure, (state, { error }) => {
        return {
            ...state,
            isResendUserLoading: false,
            isUserResent: false,
            resendUserError: error,
        };
    }),
    on(changePassword, (state) => {
        return {
            ...state,
            isChangePasswordLoading: true,
            changePasswordError: null,
        };
    }),
    on(changePasswordSuccess, (state) => {
        return {
            ...state,
            isChangePasswordLoading: false,
            changePasswordError: null,
        };
    }),
    on(changePasswordFailure, (state, { error }) => {
        return {
            ...state,
            isChangePasswordLoading: false,
            changePasswordError: error,
        };
    }),
    on(uploadProfilePicture, (state) => {
        return {
            ...state,
            isUploadProfilePictureLoading: true,
            uploadProfilePictureError: null,
        };
    }),
    on(uploadProfilePictureSuccess, (state, { profileImageUrl }) => {
        return {
            ...state,
            isUploadProfilePictureLoading: false,
            uploadProfilePictureError: null,
            userData: { ...state.userData, ...{ profileImageUrl } },
        };
    }),
    on(uploadProfilePictureFailure, (state, { error }) => {
        return {
            ...state,
            isUploadProfilePictureLoading: false,
            uploadProfilePictureError: error,
        };
    }),
    on(setupTwoFactorAuthSuccess, (state, action) => ({
        ...state,
        userData: { ...state.userData, is2FAEnabled: !action?.setup ? false : state.userData.is2FAEnabled, },
    })),
    on(verifyTwoFactorAuthSuccess, state => ({
        ...state,
        userData: {
            ...state.userData,
            is2FAEnabled: true
        }
    }))
);
