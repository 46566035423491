import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TokenReplacerService } from '@flipto/shared/src/lib/services/token-replacer/token-replacer.service';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { DiscoveryProperty } from 'account-hybrid/common/components/touchpoints/models';
import { Language } from 'account-hybrid/common/models/language.model';
import { MissionControlSettingsDetailsDto } from 'account-hybrid/features/mission-control/models';
import {
    CustomEditorComponent,
    EditorLanguageVariation,
    LanguageVariationsComponent
} from 'apps/account/src/account-shared';
import dayjs from 'dayjs';
import { PropertyHierarchyDto, PropertyHierarchyLanguageDto } from 'libs/api/data-contracts';
import { BookingEngineToken } from 'libs/shared/src/lib/services/token-replacer/booking-engine-token.enum';
import { TokenValue } from 'libs/shared/src/lib/services/token-replacer/token-value.model';
import _ from 'lodash';
import { IdentityService } from '../../../../../../core/authentication';
import {
    DiscoveryComponentIntegrationViewModel
} from './../../../models/discovery-component/discovery-component-integration-view-model.model';


interface AssociationFieldError {
    text: string;
    controlName: string;
}

@Component({
    selector: 'ft-properties-associations',
    templateUrl: './properties-associations.component.html',
    styleUrls: ['./properties-associations.component.scss']
})
export class PropertiesAssociationsComponent implements OnInit, DoCheck, OnChanges {
    @ViewChild(LanguageVariationsComponent, { static: false }) languageVariationsRef: LanguageVariationsComponent;
    _associations: PropertyHierarchyDto[];
    @Input() properties: DiscoveryProperty[];
    @Input() missionControlDetailsSettings: MissionControlSettingsDetailsDto;
    @Input() integrationViewModel: DiscoveryComponentIntegrationViewModel;

    @Input() set associations(associations: PropertyHierarchyDto[]) {
        this._associations = _.cloneDeep(this.extendAssociations(associations || []));
    };

    get associations() {
        return this._associations;
    }

    @Input() loading: boolean;
    @Output() changed = new EventEmitter<PropertyHierarchyDto[]>();
    @Output() languageChanged = new EventEmitter<Language>();
    @ViewChild('form', { static: false }) form: NgForm;
    @ViewChild('dialogTmpl', { static: false }) dialogTmpl: TemplateRef<any>;
    @ViewChildren('customEditorRef') customEditorsRef: QueryList<CustomEditorComponent>;
    isFliptoStaff = false;
    hasChanges = false;
    propertyAssociationStatus = [
        { label: `Live - All audiences will be able to see this property`, value: true },
        { label: 'Preview - Only your team can see this property', value: false }
    ];
    inheritBeOptions = [
        { label: `Route to property's booking engine`, value: true },
        { label: `Route to your booking engine`, value: false }
    ];
    inheriteWebsiteUrlOptions = [
        { label: `Route to property's website`, value: true },
        { label: 'Route to your website', value: false }
    ];
    discoveryRouterVisibilityOptions = [
        { label: `Represent this property in Discovery Router`, value: false },
        { label: 'Don’t represent this property in Discovery Router', value: true }
    ];
    discoveryPlannerFlowVisibilityOptions = [
        { label: `Show this property’s Planner Flow`, value: false },
        { label: 'Don’t show this property’s Planner Flow', value: true }
    ];
    inheritBrandingOptions = [
        { label: `${this.spacesService.current.title} controls styling`, value: false },
        { label: 'Property controls styling', value: true }
    ];
    initialAssociationsArray: PropertyHierarchyDto[] = [];
    associationDefaultModel: PropertyHierarchyDto = {
        name: null,
        propertyUuid: null,
        isActive: true,
        isHiddenInRouter: false,
        isPlannerFlowDisabled: false,
        isUsePropertyBookingEngineTemplateUrl: true,
        isUsePropertyWebsiteUrl: true,
        justAdded: false,
        languages: []
    };
    @Input() error: any;
    @Input() languages: Language[];
    @Input() selectedPreviewLanguage: Language;
    // Language variations inline implementation for single field, we should have another editor if we will have more fields
    activeVariations: EditorLanguageVariation[];

    constructor(
        private dialog: MatDialog,
        public identityService: IdentityService,
        private spacesService: SpacesService,
        private tokenReplacerService: TokenReplacerService
    ) {
    }

    ngOnInit() {
        this.isFliptoStaff = this.identityService.isFliptoStaff();
    }

    getValidationErrors(): AssociationFieldError[] {
        if (this.form?.controls) {
            return Object.entries(this.form.controls).map(([key, control]) => {
                if (control.invalid) {
                    const controlNameParse = this.splitByGuid(key);
                    const foundProperty = this.associations?.find(property => property.propertyUuid === controlNameParse?.[1]);
                    return {
                        controlName: key,
                        text: `There was an error in <span class="bold-text">${this.camelToCapitalCase(controlNameParse?.[0])}</span> for ${foundProperty?.name}`
                    };
                }
            }).filter(entry => !!entry);
        }
        return [];
    }

    trackError(index: number, item: AssociationFieldError) {
        return item?.controlName;
    }

    splitByGuid(input: string) {
        const guidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi;

        const splitParts = input.split(guidRegex);

        const guids = input.match(guidRegex) || [];

        const result = [];
        for (let i = 0; i < splitParts.length; i++) {
            result.push(splitParts[i].trim());
            if (i < guids.length) {
                result.push(guids[i]);
            }
        }

        return result.filter(part => part);
    }

    camelToCapitalCase(str: string) {
        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/^./, char => char.toUpperCase());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.associations?.currentValue) {
            this.initialAssociationsArray = _.cloneDeep(this.extendAssociations(changes.associations.currentValue));
        }
        if (changes?.languages?.currentValue) {
            this.associationDefaultModel = {
                ...this.associationDefaultModel,
                languages: (changes?.languages?.currentValue || []).map(({ languageCode }) => ({
                    languageCode,
                    websiteUrl: null
                }))
            };
        }
    }

    ngDoCheck() {
        this.hasChanges = !_.isEqual(this.initialAssociationsArray, this.associations);
    }

    onPreviewLanguageSelected(language: Language) {
        this.languageChanged.next(language);
    }

    getBookingEngineCodeValue(association: PropertyHierarchyDto, languageCode: string): string {
        const propertyHierarchyLanguage = association.languages.find(l => l.languageCode === languageCode);
        if (!association.isUsePropertyBookingEngineTemplateUrl && propertyHierarchyLanguage?.bookingEngineCode) {
            return `Route to your booking engine using property code ${propertyHierarchyLanguage.bookingEngineCode}`;
        }

        return this.getLabel(association.isUsePropertyBookingEngineTemplateUrl, this.inheritBeOptions);
    }

    getLandingPageValue(association: PropertyHierarchyDto): string {
        // Website URL overridden
        const currentTranslationData = association?.languages?.find(item => item.languageCode === this.selectedPreviewLanguage.languageCode);
        if (currentTranslationData?.websiteUrl) {
            return `Route to your website at ${currentTranslationData.websiteUrl}`;
        }

        // If there is a translation without override use the discovery url, else use the property's URL
        return this.getLabel(association.isUsePropertyWebsiteUrl, this.inheriteWebsiteUrlOptions);
    }

    prepareUrl(templateUrl: string, propertyCode: string): string {
        const defaultTokens = [
            new TokenValue(BookingEngineToken.Rooms, 1),
            new TokenValue(BookingEngineToken.Beds, 2),
            new TokenValue(BookingEngineToken.Adults, 2),
            new TokenValue(BookingEngineToken.Children, 0),
            new TokenValue(BookingEngineToken.PropertyBookingEngineCode, propertyCode),
            new TokenValue(BookingEngineToken.PromoCode, 'ABC123'),

            new TokenValue(BookingEngineToken.StartDate, dayjs().format('MM/DD/YYYY')),
            new TokenValue(BookingEngineToken.EndDate, dayjs().add(1, 'day').format('MM/DD/YYYY')),
            new TokenValue(BookingEngineToken.Nights, 7)
        ];

        return this.tokenReplacerService.replace(templateUrl, defaultTokens);
    }

    onAddProperty(propertyUuid: string) {
        const foundProperty = this.properties.find(property => property.propertyUuid === propertyUuid);
        const propertyToAdd: PropertyHierarchyDto = {
            ...this.associationDefaultModel,
            name: foundProperty.name,
            propertyUuid: foundProperty.propertyUuid,
            justAdded: true
        };
        // Specifically use a new array to force angular to notice changes
        this.associations = [propertyToAdd, ...this.associations];
    }

    toggleRemoveAssociationModal(association: PropertyHierarchyDto) {
        const dialogRef = this.dialog.open(this.dialogTmpl, {
            panelClass: 'ft2',
            backdropClass: '-dark',
            data: this
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeAssociation(association);
            }
        });
    }

    getLabel(state: boolean, options: { value: boolean, label: string }[]): string | null {
        return options.find(({ value }) => state == value)?.label;
    }

    removeAssociation(association: PropertyHierarchyDto) {
        this.customEditorsRef.find(editor => editor.visible)?.wrapperRef?.onClose();
        // explicitly new array so angular catches the changes
        this.associations = this.associations.filter(x => x.propertyUuid != association.propertyUuid);
    }

    onCancel() {
        this.associations = _.cloneDeep(this.initialAssociationsArray);
        this.hasChanges = false;
    }

    onSave() {
        this.changed.emit(this.associations.map(association => ({
            ...association,
            isHiddenInRouter: association?.isHiddenInRouter || false,
            isPlannerFlowDisabled: association?.isPlannerFlowDisabled || false
        })));
        this.hasChanges = false;
    }

    getCurrentInitialAssociation(association: PropertyHierarchyDto): PropertyHierarchyDto {
        return this.initialAssociationsArray.find(el => el.propertyUuid == association.propertyUuid) || this.associationDefaultModel;
    }

    cancelField(association: PropertyHierarchyDto, field: string, translatable?: boolean) {
        if (translatable) {
            association.languages?.forEach(translation => {
                translation[field] = _.cloneDeep(this.getCurrentInitialAssociation(association)?.languages?.find(item => item.languageCode === translation.languageCode)?.[field]);
            });
        } else {
            association[field] = _.cloneDeep(this.getCurrentInitialAssociation(association)?.[field]);
        }
    }

    changeField(event: boolean, association: PropertyHierarchyDto, field: string, translatable?: boolean) {
        if (translatable) {
            association.languages?.forEach(translation => {
                translation[field] = event ? null : _.cloneDeep(this.getCurrentInitialAssociation(association)?.languages?.find(item => item.languageCode === translation.languageCode)?.[field]) ?? '';
            });
        } else {
            association[field] = event ? null : _.cloneDeep(this.getCurrentInitialAssociation(association)?.[field]) ?? '';
        }
    }

    getValueForWebsiteUrl(association: PropertyHierarchyDto | null): boolean {
        return association?.languages?.find(item => item.languageCode === this.selectedPreviewLanguage.languageCode)?.websiteUrl ? this.inheriteWebsiteUrlOptions[1].value : this.inheriteWebsiteUrlOptions[0].value;
    }

    getLanguageNameForTranslation(translation: PropertyHierarchyLanguageDto) {
        return this.languages?.find(language => language.languageCode === translation.languageCode)?.languageName;
    }

    onActiveVariationChanged(variations: EditorLanguageVariation[]) {
        this.activeVariations = variations;
    }

    isVariationActive(languageCode: string) {
        return this.activeVariations?.find(variation => variation.languageCode === languageCode)?.isVariationActive;
    }

    get activeVariationsCount(): number {
        return this.activeVariations?.filter(variation => variation.isVariationActive)?.length;
    }

    isValueRemovable(association: PropertyHierarchyDto, i: number) {
        return association?.languages?.find((_, index) => i === index)?.languageCode !== 'en';
    }

    removeValue(association: PropertyHierarchyDto, i: number, fieldName: string) {
        if (Array.isArray(association.languages)) {
            association.languages[i][fieldName] = '';
            this.languageVariationsRef.removeVariation(association?.languages?.[i]?.languageCode);
        }
    }

    onRoutingEditorOpened(association: PropertyHierarchyDto, fieldName: string) {
        this.activeVariations = [
            ...this.languages.map(language => ({
                ...language,
                isVariationActive: language.languageCode === 'en' || association?.languages?.find(value => value?.languageCode === language.languageCode)?.[fieldName]?.length > 0
            }))
        ];
    }

    extendAssociations(associations: PropertyHierarchyDto[]): PropertyHierarchyDto[] {
        return associations.map(association => ({
            ...association,
            languages: association.languages?.map(language => ({
                ...language,
                languageName: this.languages?.find(item => item.languageCode === language.languageCode)?.languageName,
                // @todo introduce some better way of data mapping, i.e. dtoToPlain <-> plainToDto
                bookingEngineCode: language.bookingEngineCode ?? null
            }))
        }));
    }
}
