import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    resetTwoFactorAuth,
    resetTwoFactorAuthRecoveryCodes,
    resetTwoFactorAuthRecoveryCodesFailure,
    resetTwoFactorAuthRecoveryCodesSuccess,
    setupTwoFactorAuth,
    setupTwoFactorAuthFailure,
    setupTwoFactorAuthSuccess,
    verifyTwoFactorAuth,
    verifyTwoFactorAuthFailure,
    verifyTwoFactorAuthSuccess,
} from './two-factor-auth-setup.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TwoFactorAuthSetupEffects {
    setupTwoFactorAuth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setupTwoFactorAuth),
            map((action) => action.is2FAEnabled),
            switchMap((is2FAEnabled) =>
                this.authService.setup2FA(is2FAEnabled).pipe(
                    map((setup) => setupTwoFactorAuthSuccess({ setup })),
                    catchError((error) =>
                        of(
                            setupTwoFactorAuthFailure({
                                error: error?.error?.Message,
                            })
                        )
                    )
                )
            )
        )
    );

    onResetTwoFactorAuth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetTwoFactorAuth),
            switchMap(() =>
                this.authService.setup2FA(false).pipe(
                    map(() => setupTwoFactorAuth({ is2FAEnabled: true })),
                    catchError((error) =>
                        of(
                            setupTwoFactorAuthFailure({
                                error: error?.error?.Message,
                            })
                        )
                    )
                )
            )
        )
    );

    verifyTwoFactorAuth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyTwoFactorAuth),
            map((action) => action.otp),
            switchMap((otp) =>
                this.authService.verify2FA(otp).pipe(
                    map((response) =>
                        verifyTwoFactorAuthSuccess({ recoveryCodes: response })
                    ),
                    catchError((error) =>
                        of(
                            verifyTwoFactorAuthFailure({
                                error: error?.error?.Message,
                            })
                        )
                    )
                )
            )
        )
    );

    resetTwoFactorAuthRecoveryCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetTwoFactorAuthRecoveryCodes),
            switchMap(() =>
                this.authService.reset2FARecoveryCodes().pipe(
                    map((response) =>
                        resetTwoFactorAuthRecoveryCodesSuccess({
                            recoveryCodes: response,
                        })
                    ),
                    catchError((error) =>
                        of(
                            resetTwoFactorAuthRecoveryCodesFailure({
                                error: error?.error?.Message,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthenticationApiService
    ) {}
}
