<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div [style.width]="'100%'"
         [style.min-width]="'670px'"
         [style.max-width]="'670px'">
        <ng-container *ngIf="isArrayValue(); else textValue">
            <ft-text-html-editor *ngFor="let item of value; let i = index;"
                                 #editor
                                 [visible]="visible"
                                 [model]="item"
                                 [required]="required"
                                 [currentIndex]="i"
                                 [email]="email"
                                 [plain]="plain"
                                 [visibleFormats]="visibleFormats"
                                 [trimClipboardFormat]="trimClipboardFormat"
                                 [maxlength]="maxlength"
                                 [minlength]="minlength"
                                 [pattern]="pattern"
                                 [tokens]="tokens"
                                 [totalEntries]="value?.length"
                                 [fields]="fields">
            </ft-text-html-editor>
        </ng-container>
        <ng-template #textValue>
            <ft-text-html-editor [(model)]="value"
                                 #editor
                                 [required]="required"
                                 [visible]="visible"
                                 [email]="email"
                                 [maxlength]="maxlength"
                                 [plain]="plain"
                                 [visibleFormats]="visibleFormats"
                                 [trimClipboardFormat]="trimClipboardFormat"
                                 [minlength]="minlength"
                                 [pattern]="pattern"
                                 [tokens]="tokens"
                                 [fields]="fields">
            </ft-text-html-editor>
        </ng-template>
    </div>
</ft-modal-editor-wrapper>
