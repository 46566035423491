import { Component, Input, OnInit } from '@angular/core';
import { SignInDto } from '../../models/sign-in-dto';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SignInStates } from '../../models/sign-in-states.enum';
import { SignInError } from '../../models/sign-in-error';
import { SignInErrorMessages } from '../../models/sign-in-error-messages.enum';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import {
    getSignInError,
    getSignInState,
    isSignInLoading,
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import {
    setSignInRedirectTo,
    setSignInState,
    signIn, twoFactorAuthSignIn
} from 'account-hybrid/features/authentication/store/authentication/authentication.actions';
import { TwoFactorAuthSignInDto } from 'account-hybrid/features/authentication/models/two-factor-auth-sign-in-dto';
import { SignInBaseComponent } from 'account-hybrid/features/authentication/pages/sign-in-base/sign-in-base.component';

@Component({
    selector: 'ft-two-factor-auth-sign-in',
    templateUrl: './two-factor-auth-sign-in.component.html',
    styleUrls: ['./two-factor-auth-sign-in.component.scss'],
})
export class TwoFactorAuthSignInComponent extends SignInBaseComponent implements OnInit {

    constructor(public store: Store<AuthenticationState>) {
        super(store);
    }

    signIn(formValue: TwoFactorAuthSignInDto) {
        this.store.dispatch(twoFactorAuthSignIn({ data: formValue }));
    }

    changeState(state: SignInStates) {
        this.store.dispatch(setSignInState({ signInState: state }));
    }
}
