import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from "./authentication/authentication.state";
import {
    AUTHENTICATION_FEATURE_NAME
} from './authentication/authentication-feature-name.const';
import { AppAuthState } from 'account-hybrid/features/authentication/store/app-auth.state';
import { APP_AUTH_FEATURE_NAME } from 'account-hybrid/features/authentication/store/app-auth-feature-name.const';

export const getAppAuthFeatureState = createFeatureSelector<AppAuthState>(APP_AUTH_FEATURE_NAME)

export const getAuthenticationState = createSelector(
    getAppAuthFeatureState,
    state => state[AUTHENTICATION_FEATURE_NAME]
)

// sign in
export const isSignInLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isSignInLoading
);

export const getSignInError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.signInError
);

export const getSignInState = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.signInState
);

export const getSignInRedirectTo = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.signInRedirectTo
);

// forgot password
export const isForgotPasswordLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isForgotPasswordLoading
);

export const getForgotPasswordState = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.forgotPasswordState
);

export const getForgotPasswordError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.forgotPasswordError
);

export const getForgotPasswordSentNew = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.forgotPasswordSentNew
);

// change password
export const isResetPasswordLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isResetPasswordLoading
);

export const getResetPasswordError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.resetPasswordError
);


// create user
export const isChangeUserLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isChangeUserLoading
);

export const isUploadProfilePictureLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isUploadProfilePictureLoading
);

export const getChangeUserError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.changeUserError
);

export const getUploadProfilePictureError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.uploadProfilePictureError
);

export const getUserData = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.userData
);

// token verification
export const isTokenVerificationLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isTokenVerificationLoading
);

export const getTokenVerificationError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.tokenVerificationError
);

// resend user
export const isResendUserLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isResendUserLoading
);

export const getResendUserError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.resendUserError
);

export const isUserResent = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isUserResent
);


// change password
export const isChangePasswordLoading = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.isChangePasswordLoading
);

export const getChangePasswordError = createSelector(
    getAuthenticationState,
    (state: AuthenticationState) => state.changePasswordError
);

export const selectTwoFactorAuthRedirectTrigger = createSelector(
    getAuthenticationState,
    state => state.twoFactorAuthRedirectTrigger
);
