import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AuthError } from "../../models/auth-error";
import { ForgotPasswordStates } from "../../models/forgot-password-states.enum";
import { takeWhile } from "rxjs/operators";
import { Actions, ofType } from '@ngrx/effects';
import {
    enterSite,
    resetPassword,
    resetPasswordSuccess,
    sendForgotPasswordEmail,
    verifyToken,
} from '../../store/authentication/authentication.actions';
import {
    getForgotPasswordError, getForgotPasswordState, getResetPasswordError,
    getTokenVerificationError, isForgotPasswordLoading, isResetPasswordLoading,
    isTokenVerificationLoading
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';

@Component({
    selector: 'ft-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    @Input() token: string;
    @Input() userUuid: string;
    tokenVerificationLoading$: Observable<boolean>;
    tokenVerificationError$: Observable<AuthError>;
    forgotPasswordLoading$: Observable<boolean>;
    forgotPasswordError$: Observable<AuthError>;
    forgotPasswordState$: Observable<ForgotPasswordStates>;
    forgotPasswordStates = ForgotPasswordStates;
    resetPasswordLoading$: Observable<boolean>;
    resetPasswordError$: Observable<AuthError>;
    password: string;
    isAlive = true;


    constructor(private store: Store<AuthenticationState>,
                private actions$: Actions) {
        this.tokenVerificationLoading$ = this.store.pipe(select(isTokenVerificationLoading));
        this.tokenVerificationError$ = this.store.pipe(select(getTokenVerificationError));
        this.forgotPasswordLoading$ = this.store.pipe(select(isForgotPasswordLoading));
        this.forgotPasswordError$ = this.store.pipe(select(getForgotPasswordError));
        this.forgotPasswordState$ = this.store.pipe(select(getForgotPasswordState));
        this.resetPasswordLoading$ = this.store.pipe(select(isResetPasswordLoading));
        this.resetPasswordError$ = this.store.pipe(select(getResetPasswordError));
    }

    ngOnInit() {
        this.verifyToken();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    verifyToken() {
        this.store.dispatch(verifyToken({
            userUuid: this.userUuid,
            token: this.token
        }));
    }

    submit() {
        this.store.dispatch(resetPassword({
                data: {
                    password: this.password,
                    token: this.token,
                    userUuid: this.userUuid
                }
            })
        );

        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(resetPasswordSuccess),
        ).subscribe(() => {
            this.store.dispatch(enterSite());
        });
    }

    requestLink() {
        this.store.dispatch(sendForgotPasswordEmail({
            data: {
                userUuid: this.userUuid
            }
        }));
    }
}
