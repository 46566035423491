import { Component, OnInit, ViewChild } from '@angular/core';
import { IdentityService } from '../../../../core/authentication/services/identity.service';
import { UserData } from '../../models/user-data';
import { select, Store } from '@ngrx/store';
import { ChangePasswordDto } from '../../models/change-password-dto';
import { Observable } from 'rxjs';
import { StateService } from '@uirouter/core';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import {
    getChangePasswordError,
    getChangeUserError,
    getUploadProfilePictureError,
    getUserData,
    isChangePasswordLoading,
    isChangeUserLoading,
    isUploadProfilePictureLoading,
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import {
    changePassword,
    changeUser,
    uploadProfilePicture,
} from '../../store/authentication/authentication.actions';
import {
    selectTwoFactorAuthRecoveryCodes,
    selectTwoFactorAuthSetupData,
    selectTwoFactorAuthSetupError,
    selectTwoFactorAuthSetupIsLoading,
} from 'account-hybrid/features/authentication/store/two-factor-auth-setup.selectors';
import { TwoFactorAuthSetupResponse } from 'account-hybrid/features/authentication/models/two-factor-auth-setup-response';
import {
    resetTwoFactorAuth,
    resetTwoFactorAuthRecoveryCodes,
    setupTwoFactorAuth,
    verifyTwoFactorAuth,
} from 'account-hybrid/features/authentication/store/two-factor-auth-setup/two-factor-auth-setup.actions';
import { CustomEditorComponent } from 'account-shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TwoFactorWizardStep } from 'account-hybrid/features/authentication/pages/two-factor-auth-wizard/two-factor-auth-wizard.component';

@UntilDestroy()
@Component({
    selector: 'ft-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    @ViewChild('twoFactorAuthStatusEditor')
    twoFactorAuthStatusEditor: CustomEditorComponent;
    userData$: Observable<UserData> = this.store.pipe(select(getUserData));

    changePasswordLoading$: Observable<boolean> = this.store.pipe(
        select(isChangePasswordLoading)
    );
    changeUserLoading$: Observable<boolean> = this.store.pipe(
        select(isChangeUserLoading)
    );
    uploadProfilePictureLoading$: Observable<boolean> = this.store.pipe(
        select(isUploadProfilePictureLoading)
    );

    changePasswordError$: Observable<any> = this.store.pipe(
        select(getChangePasswordError)
    );
    changeUserError$: Observable<any> = this.store.pipe(
        select(getChangeUserError)
    );
    uploadProfilePictureError$: Observable<any> = this.store.pipe(
        select(getUploadProfilePictureError)
    );
    twoFactorSetupIsLoading$: Observable<boolean> = this.store.pipe(
        select(selectTwoFactorAuthSetupIsLoading)
    );
    twoFactorSetupData$: Observable<TwoFactorAuthSetupResponse> =
        this.store.pipe(select(selectTwoFactorAuthSetupData));
    twoFactorAuthRecoveryCodes$: Observable<string[]> = this.store.pipe(
        select(selectTwoFactorAuthRecoveryCodes)
    );
    twoFactorAuthError$: Observable<any> = this.store.pipe(
        select(selectTwoFactorAuthSetupError)
    );
    files: File[];

    twoFactorAuthWizardSteps = TwoFactorWizardStep;

    closeButtonVisible = false;

    constructor(
        public identityService: IdentityService,
        private stateService: StateService,
        private store: Store<AuthenticationState>
    ) {
        this.closeButtonVisible = !!this.getBackUrl();
    }

    ngOnInit() {}

    goBack() {
        document.location.href = this.getBackUrl();
    }

    changeUser(profileData: Partial<UserData>) {
        this.store.dispatch(changeUser({ data: profileData }));
    }

    changePassword(data: ChangePasswordDto) {
        this.store.dispatch(changePassword({ data }));
    }

    uploadProfilePicture(files: File[]) {
        this.store.dispatch(uploadProfilePicture({ file: files[0] }));
    }

    getBackUrl() {
        const params = new URLSearchParams(this.stateService.params);
        return params.get('backUrl');
    }

    turnOffTwoFactorAuth() {
        this.store.dispatch(
            setupTwoFactorAuth({
                is2FAEnabled: false,
            })
        );
    }

    resetTwoFactorAuthRecoveryCodes() {
        this.store.dispatch(resetTwoFactorAuthRecoveryCodes());
    }

    onVerifyTwoFactorAuthOtp(otp: string) {
        this.store.dispatch(verifyTwoFactorAuth({ otp }));
    }

    onTwoFactorAuthWizardOpened(step: TwoFactorWizardStep) {
        if (step === TwoFactorWizardStep.Setup) {
            this.store.dispatch(
                setupTwoFactorAuth({
                    is2FAEnabled: true,
                })
            );
        }
    }

    onResetTwoFactorAuth() {
        this.store.dispatch(resetTwoFactorAuth());
    }
}
