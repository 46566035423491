import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import {
    TwoFactorAuthSetupResponse
} from 'account-hybrid/features/authentication/models/two-factor-auth-setup-response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';

export enum TwoFactorWizardStep {
    Setup,
    Edit,
    RecoveryCodes
}

export const TWO_FACTOR_AUTH_WIZARD_MODAL_ID = 'ftTwoFactorAuthWizard'

@Component({
    selector: 'ft-two-factor-auth-wizard',
    templateUrl: './two-factor-auth-wizard.component.html',
    styleUrls: ['./two-factor-auth-wizard.component.scss'],
})
export class TwoFactorAuthWizardComponent implements OnChanges {
    @Input() twoFactorAuthSetupData: TwoFactorAuthSetupResponse;
    @Input() recoveryCodes: string[];
    @Input() step: TwoFactorWizardStep = TwoFactorWizardStep.Setup;
    stepsTypes = TwoFactorWizardStep;
    @Input() loading$: Observable<boolean>;
    @Input() error: string;
    protected modalId = TWO_FACTOR_AUTH_WIZARD_MODAL_ID;

    @Output() opened = new EventEmitter<TwoFactorWizardStep>();
    @Output() verifyOtp = new EventEmitter<string>();
    @Output() generateRecoveryCodes = new EventEmitter();
    @Output() resetTwoFactorAuth = new EventEmitter();
    @Output() turnOffTwoFactorAuth = new EventEmitter();

    otpFormGroup: FormGroup;

    constructor(private fb: FormBuilder, private dialog: MatDialog) {
        this.otpFormGroup = this.fb.group({
            otpCode: [null, Validators.required],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.recoveryCodes?.currentValue?.length) {
            this.step = TwoFactorWizardStep.RecoveryCodes;
        }
    }

    onVerifyOtp() {
        if (this.otpFormGroup.valid) {
            this.verifyOtp.emit(this.otpFormGroup.value.otpCode);
        }
    }

    closeSetupModal() {
        this.dialog.getDialogById(TWO_FACTOR_AUTH_WIZARD_MODAL_ID)?.close();
        this.otpFormGroup.reset();
    }

    closeRecoveryCodesView() {
        this.closeSetupModal();
        this.step = TwoFactorWizardStep.Edit;
    }

    onOpen() {
        this.opened.emit(this.step);
    }

    onGenerateRecoveryCodes() {
        this.step = TwoFactorWizardStep.RecoveryCodes;
        this.generateRecoveryCodes.emit();
    }

    onResetTwoFactorAuth() {
        this.step = TwoFactorWizardStep.Setup;
        this.resetTwoFactorAuth.emit();
    }

    onTurnOffTwoFactorAuth() {
        this.turnOffTwoFactorAuth.emit();
        this.closeSetupModal();
        this.step = TwoFactorWizardStep.Setup;
    }
}
