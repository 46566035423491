import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    Environment,
    ENVIRONMENT_TOKEN,
} from '../../../core/environment.service';
import { SignInDto } from '../models/sign-in-dto';
import { Observable, throwError, timer } from 'rxjs';
import { ResetPasswordDto } from '../models/reset-password-dto';
import { SpacesService } from '../../../common/components/spaces/spaces.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ForgotPasswordDto } from '../models/forgot-password-dto';
import { UserData } from '../models/user-data';
import { ChangePasswordDto } from '../models/change-password-dto';
import { User } from '../../team/models/user.model';
import { TwoFactorAuthSetupResponse } from 'account-hybrid/features/authentication/models/two-factor-auth-setup-response';
import { TwoFactorAuthRecoveryCodesResponse } from 'account-hybrid/features/authentication/models/two-factor-auth-recovery-codes-response';
import { TwoFactorAuthSignInDto } from 'account-hybrid/features/authentication/models/two-factor-auth-sign-in-dto';
import { TwoFactorRecoveryDto } from 'account-hybrid/features/authentication/models/two-factor-recovery-dto';

@Injectable({ providedIn: 'root' })
export class AuthenticationApiService {
    apiUrl: string;

    constructor(
        private http: HttpClient,
        private spacesService: SpacesService,
        @Inject(ENVIRONMENT_TOKEN) private environment: Environment
    ) {
        this.apiUrl = `${this.environment.apiV1BaseUrl}api/auth`;
    }

    signIn(data: SignInDto): Observable<User> {
        return this.http
            .post<User>(`${this.apiUrl}/login`, { ...data })
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    twoFactorAuthSignIn(data: TwoFactorAuthSignInDto): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/login/2fa`, data)
    }

    twoFactorAuthRecovery(data: TwoFactorRecoveryDto): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/login/2fa/recovery`, data)
    }

    verifyToken(token: string, userUuid: string): Observable<UserData> {
        return this.http
            .put<any>(`${this.apiUrl}/verify-reset-token`, { token, userUuid })
            .pipe(
                map((res) => {
                    return {
                        email: res.Email,
                        firstName: res.First,
                        lastName: res.Last,
                    };
                }),
                catchError((err) => this.errorHandler(err))
            );
    }

    sendForgotPasswordEmail(data: Partial<ForgotPasswordDto>) {
        return this.http
            .post(`${this.apiUrl}/send-reset-email`, { ...data })
            .pipe(
                catchError((err) => {
                    if (err.error.Email) {
                        err.error.Message = 'INVALID_EMAIL_FORMAT';
                    }
                    return this.errorHandler(err);
                })
            );
    }

    resendUser(userUuid: string, companyCode: string) {
        return this.http
            .post(`${this.apiUrl}/send-new-user-email `, {
                userUuid,
                companyCode,
            })
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    resetPassword(data: ResetPasswordDto): Observable<User> {
        return this.http
            .post<User>(`${this.apiUrl}/reset-password`, { ...data })
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    sendResetEmail(email: string) {
        return this.http
            .post<User>(`${this.apiUrl}/send-reset-email`, { email })
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    changeUser(data: UserData) {
        return this.http
            .post(`${this.environment.apiBaseUrl}/users/me`, data)
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    uploadProfilePicture(file: File) {
        const data = new FormData();
        data.append('file', file, file.name);
        return this.http
            .post<{
                profileImageUrl: string;
            }>(`${this.environment.apiBaseUrl}/profile-pictures`, data)
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    changePassword(data: ChangePasswordDto) {
        return this.http
            .post(
                `${this.environment.apiBaseUrl}/users/me/change-password`,
                data
            )
            .pipe(catchError((err) => this.errorHandler(err)));
    }

    setup2FA(is2FAEnabled: boolean): Observable<TwoFactorAuthSetupResponse> {
        return this.http.post<TwoFactorAuthSetupResponse>(
            `${this.environment.apiBaseUrl}/users/me/2fa`,
            { is2FAEnabled }
        );
    }

    verify2FA(otpCode: string): Observable<string[]> {
        return this.http
            .post<TwoFactorAuthRecoveryCodesResponse>(
                `${this.environment.apiBaseUrl}/users/me/2fa/verify`,
                { otpCode }
            )
            .pipe(map((response) => response?.recoveryCodes));
    }

    reset2FARecoveryCodes(): Observable<string[]> {
        return this.http
            .post<TwoFactorAuthRecoveryCodesResponse>(
                `${this.environment.apiBaseUrl}/users/me/2fa/reset`, null
            )
            .pipe(map((response) => response?.recoveryCodes));
    }

    errorHandler(response: any) {
        const authError = {
            Status: response.status,
            Message:
                response.status === 500
                    ? 'Error processing request'
                    : response.error.Message,
        };
        return throwError(authError);
    }

    retryStrategy(maxRetryAttempts = 3, delayTime = 300) {
        return (errors: Observable<any>) => {
            return errors.pipe(
                mergeMap((error, i) => {
                    const retryAttempt = i + 1;
                    if (
                        retryAttempt >= maxRetryAttempts ||
                        error.message ||
                        (error.error && error.error.Message) ||
                        error.status === 404
                    ) {
                        return throwError(error);
                    }
                    return timer(retryAttempt * delayTime);
                })
            );
        };
    }
}
