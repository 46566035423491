import { getSession } from 'account-hybrid/core/authentication';
import { storedHistory } from '..';
import { createSpaces } from '../store/reducers/spaces.reducer';
import { SpaceType } from './space-type';


export interface SpaceBase {
    id: string;
    slug: string;
    type: SpaceType;
    directMatchSearchFields: string[];
    indirectMatchSearchFields: string[];
    title: string;
    backgroundImageUrl: string;
    icon?: string;
    timesVisited?: number;
    sponsorName?: string;
    description?: string;
    destinationName?: string;
    permissions?: number;
    property?: PropertySpace;
}

export interface PropertySpace extends SpaceBase {
}

export interface CompanySpace extends SpaceBase {
}

export interface MixedSpace extends SpaceBase {
    company: CompanySpace;
    property: PropertySpace;
}

export interface DestinationSpace extends SpaceBase {

}


export const ParamToSpaceTypeMapping = {
    p: SpaceType.Property,
    c: SpaceType.Company,
    m: SpaceType.Mixed,
    d: SpaceType.Collaborative
};

export const SpaceTypeToParamMapping = {
    [SpaceType.Property]: "p",
    [SpaceType.Company]: "c",
    [SpaceType.Mixed]: "m",
    [SpaceType.Collaborative]: "d"
};

export interface SpaceAttributes { type: SpaceType; slug: string; }
export interface UiRouterStateSpaceAttributes { type: string; slug: string; }

export function getSpaceAttributesFromPath(path: string): SpaceAttributes | null {
    if (!path || !/^\/(p|c|m|d)\/([^\/]*)/gi.test(path)) { return null; }
    const match = /^\/(p|c|m|d)\/([^\/]*)/gi.exec(path);
    return { type: ParamToSpaceTypeMapping[match[1]], slug: match[2] };
}

export function getCurrentSpaceFromPath(): SpaceBase | null {
    const userSession = getSession();
    const attrs = getSpaceAttributesFromPath(document.location.pathname);
    if (!userSession || !attrs) { return null; }
    const spaces = createSpaces({ companies: userSession.Companies, properties: userSession.Properties, history: storedHistory() });
    return spaces.find(s => s.type === attrs.type && s.slug === attrs.slug);
}

export function getSpacesForCurrentUser(): CompanySpace[] {
    const userSession = getSession();
    return createSpaces({ companies: userSession.Companies, properties: userSession.Properties, history: storedHistory() })
}

export function getSpaceBySlug(slug: string): SpaceBase {
    const spaces = getSpacesForCurrentUser();
    return spaces?.find(space => {
        if (!!space.property && space.property.slug == slug) return true;
        return space.slug == slug;
    });
}

export function getPathForSpace(space: SpaceBase): string {
    return `/${SpaceTypeToParamMapping[space.type]}/${space.slug}`;
}

export function getSpaceAttributesFromSpace(space: SpaceBase): UiRouterStateSpaceAttributes {
    if (space) return { type: SpaceTypeToParamMapping[space.type], slug: space.slug };
}

export function getSpaceBySpaceAttributes(attrs: SpaceAttributes): SpaceBase {
    const userSession = getSession();
    if (!userSession) { return null; }
    const spaces = createSpaces({ companies: userSession.Companies, properties: userSession.Properties, history: storedHistory() });
    return spaces.find(s => s.type === attrs.type && s.slug === attrs.slug);
}
