import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import Quill from 'quill';
import { NgModel } from '@angular/forms';
import { EditorField } from '../../../models/editor-field';
import { EditorToken } from '../../../models/editor-token';
import { MonacoEditorConstructionOptions } from '@materia-ui/ngx-monaco-editor';
import { PlainClipboard } from '../../../classes/plain-clipboard';

const bold = Quill.import('formats/bold');
const italic = Quill.import('formats/italic');
const Link = Quill.import('formats/link');

bold.tagName = 'b';
Quill.register(bold, true);

italic.tagName = 'i';
Quill.register(italic, true);

class NewWindowLink extends Link {
    static create(value) {
        let node = super.create(value);
        value = this.sanitize(value);
        node.setAttribute('href', value);
        node.setAttribute('target', '_blank');
        return node;
    }
}

Quill.register(NewWindowLink, true);


@Component({
    selector: 'ft-text-html-editor',
    templateUrl: 'text-html-editor.component.html',
    styleUrls: ['./text-html-editor.component.scss']
})
export class TextHtmlEditorComponent implements OnChanges {
    @ViewChildren('input', { read: NgModel }) inputs: QueryList<NgModel>;
    @Input() plain = false;
    @Input() trimClipboardFormat = false;
    @Input() fields: EditorField[];
    @Input() tokens: EditorToken[];
    @Input() required: boolean;
    @Input() visible = true;
    @Input() email: boolean;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: string;
    @Input() placeholder = '';
    @Input() model: any;
    @Input() currentIndex: number;
    @Input() totalEntries: number;
    @Output() modelChange = new EventEmitter<any>();

    isSourceCodeVisible = false;
    selectedToken = null;
    activeEditorIndex: number | null = null;
    quillEditors: Quill[] = [];
    monacoEditors: any[] = [];
    quillConfig = {
        toolbar: !this.plain ? {
            toolbar: '#toolbar',
            container: [
                ['bold']
            ]
        } : false,
        clipboard: {
            matchVisual: false
        }
    };
    @Input() visibleFormats: string[] = ['*'];
    formats: any = this.plain ? ['bold', 'italic', 'underline', 'link', [{ 'list': 'ordered' }, { 'list': 'bullet' }]] : null;
    monacoOptions: MonacoEditorConstructionOptions = {
        formatOnPaste: true,
        formatOnType: true,
        autoIndent: 'keep',
        wordWrap: 'on',
        fontSize: 16,
        language: 'html'
    };

    trimUnbreakableSpaceOnPaste(e: ClipboardEvent, field: EditorField) {
        if (field) {
            this.model[field.name] = this.model[field.name].replace(/&(nbsp);/g, ' ');
        } else {
            this.model = this.model.replace(/&(nbsp);/g, ' ');
        }
    }

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.trimClipboardFormat?.currentValue && !(Quill.import('modules/clipboard') === PlainClipboard)) {
            Quill.register('modules/clipboard', PlainClipboard, true);
        }

    }

    onModelChanged(model: string, field?: EditorField) {
        if (field) {
            this.model[field.name] = model;
        } else {
            this.model = model;
        }

        this.modelChange.next(this.model);
    }

    onBlur(i) {
        if (i === this.activeEditorIndex && this.fields) {
            this.activeEditorIndex = null;
        }
    }

    onFocus(index: number) {
        this.activeEditorIndex = index;
    }

    onQuillEditorCreated(event, index) {
        const indexToFocusInArray = !isNaN(this.currentIndex) ? this.currentIndex : index;
        this.quillEditors[index] = event;

        if (indexToFocusInArray === 0) {
            this.focusEditor(event);
        }
    }

    focusEditor(editor) {
        setTimeout(() => {
            editor.focus();
        }, 200);
    }

    monacoEditorCreated(event, index) {
        this.monacoEditors[index] = event;
    }

    changeFormat() {
        this.isSourceCodeVisible = !this.isSourceCodeVisible;
    }

    onTokenSelected(token: string) {
        if (this.isSourceCodeVisible) {
            this.insertTokenInMonaco(token);
        } else {
            this.insertTokenInQuill(token);
        }

        setTimeout(() => {
            this.selectedToken = null;
        });
    }


    insertTokenInQuill(token: string) {
        if (token) {
            const selection = this.quillEditors[this.activeEditorIndex].getSelection();
            const cursorPosition = selection ? selection.index : 0;
            this.quillEditors[this.activeEditorIndex].insertText(cursorPosition, token);
            this.quillEditors[this.activeEditorIndex].setSelection(cursorPosition, token.length);
        }
    }

    insertTokenInMonaco(token: string) {
        if (token) {
            const selection = this.monacoEditors[this.activeEditorIndex].getSelection();
            const range = new monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn);
            const insertToken = { range, text: token, forceMoveMarkers: true };
            this.monacoEditors[this.activeEditorIndex].executeEdits('tokens', [insertToken]);
        }
    }

    isFormatVisible(format: string): boolean {
        return this.visibleFormats?.some(entry =>  entry === format || entry === '*');
    }
}

