import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SignInStates } from '../../models/sign-in-states.enum';
import { SignInError } from '../../models/sign-in-error';
import { SignInErrorMessages } from '../../models/sign-in-error-messages.enum';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import {
    getSignInError,
    getSignInState,
    isSignInLoading,
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import {
    setSignInRedirectTo,
    setSignInState,
} from 'account-hybrid/features/authentication/store/authentication/authentication.actions';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'ft-sign-in-base',
    template: '<ng-content></ng-content>',
    standalone: true,
})
export class SignInBaseComponent implements OnInit {
    @Input() redirectTo: string;
    loading$: Observable<boolean>;
    error$: Observable<SignInError>;
    state$: Observable<SignInStates>;
    signInStates = SignInStates;
    signInErrorMessages = SignInErrorMessages;

    constructor(public store: Store<AuthenticationState>) {
        this.loading$ = this.store.pipe(select(isSignInLoading));
        this.error$ = this.store.pipe(select(getSignInError));
        this.state$ = this.store.pipe(select(getSignInState));
    }

    ngOnInit() {
        this.store.dispatch(
            setSignInRedirectTo({ redirectTo: this.redirectTo })
        );
    }

    signIn(formValue: any) {}

    changeState(state: SignInStates) {
        this.store.dispatch(setSignInState({ signInState: state }));
    }
}
