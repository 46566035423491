import { ActionReducerMap } from '@ngrx/store';
import { AppAuthState } from 'account-hybrid/features/authentication/store/app-auth.state';
import { authenticationReducer } from 'account-hybrid/features/authentication/store/authentication/authentication.reducer';
import {
    twoFactorAuthSetupReducer
} from 'account-hybrid/features/authentication/store/two-factor-auth-setup/two-factor-auth-setup.reducer';

export const appAuthReducers: ActionReducerMap<AppAuthState> = {
    authentication: authenticationReducer,
    twoFactorAuthSetupState: twoFactorAuthSetupReducer,
};
