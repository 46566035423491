import { createAction, props } from '@ngrx/store';
import { TwoFactorAuthSetupResponse } from '../../models/two-factor-auth-setup-response';

export const setupTwoFactorAuth = createAction(
    '[Two Factor Authentication] Setup Two Factor Auth',
    props<{ is2FAEnabled: boolean }>()
);

export const setupTwoFactorAuthSuccess = createAction(
    '[Two Factor Authentication] Setup Two Factor Auth Success',
    props<{ setup: TwoFactorAuthSetupResponse }>()
);

export const setupTwoFactorAuthFailure = createAction(
    '[Two Factor Authentication] Setup Two Factor Auth Failure',
    props<{ error: any }>()
);

export const resetTwoFactorAuth = createAction(
    '[Two Factor Authentication] Reset Two Factor Auth'
);

export const verifyTwoFactorAuth = createAction(
    '[Two Factor Authentication] Verify Two Factor Auth',
    props<{ otp: string }>()
);

export const verifyTwoFactorAuthSuccess = createAction(
    '[Two Factor Authentication] Verify Two Factor Auth Success',
    props<{ recoveryCodes: string[] }>()
);

export const verifyTwoFactorAuthFailure = createAction(
    '[Two Factor Authentication] Verify Two Factor Auth Failure',
    props<{ error: any }>()
);

export const resetTwoFactorAuthRecoveryCodes = createAction(
    '[Two Factor Authentication] Reset Two Factor Auth Recovery Codes',
)

export const resetTwoFactorAuthRecoveryCodesSuccess = createAction(
    '[Two Factor Authentication] Reset Two Factor Auth Recovery Codes Success',
    props<{ recoveryCodes: string[] }>()
)

export const resetTwoFactorAuthRecoveryCodesFailure = createAction(
    '[Two Factor Authentication] Reset Two Factor Auth Recovery Codes Failure',
    props<{ error: any }>()
)
