<div #trigger>
    <ng-content>
    </ng-content>
</div>

<ft-modal2 [modalId]="modalId"
           (opened)="onOpen()"
           [trigger]="trigger">
    <div class="ft2-edit-dialog_body" customTemplate>
        <ng-container [ngSwitch]="step">
            <ng-container *ngSwitchCase="stepsTypes.Setup">
                <div class="ft2-edit-dialog_header">
                    <div class="ft2-edit-dialog_heading">
                        Two-factor Authentication
                    </div>
                    <div class="ft2-edit-dialog_summary">
                        Two-factor authentication adds an extra level of security to your account. To enable it, scan the QR code or manually enter the setup key in your authenticator app,
                        then enter the verification code you receive and save.
                    </div>
                </div>
                <ft-loading [loading$]="loading$">
                </ft-loading>
                <ng-container *ngIf="twoFactorAuthSetupData as data">
                    <div class="ft2-edit-dialog_modal v-center f-wrap mb-3">
                        <div>
                            <ngx-qrcode elementType="url" [value]="data?.qrCodeUrl">
                            </ngx-qrcode>
                        </div>
                        <p>Manual setup code: <br> <span class="bold-text strong-text">{{ data?.key }}</span></p>
                    </div>
                    <form class="ft2-form-group" [formGroup]="otpFormGroup">
                        <label>
                            Enter verification code
                        </label>
                        <input (keydown.enter)="otpFormGroup.valid && onVerifyOtp()"
                               placeholder="Code"
                               type="text"
                               class="ft2-input -large"
                               autocomplete="off"
                               formControlName="otpCode">
                    <div class="ft2-form-error" *ngIf="error">
                        {{ error }}
                    </div>
                    </form>
                </ng-container>
                <div class="ft2-edit-dialog_footer">
                    <button class="ft2-btn-light -outline -float-left -large"
                            (click)="closeSetupModal()">
                        Cancel
                    </button>
                    <button (click)="onVerifyOtp()"
                            [disabled]="!otpFormGroup.valid || (loading$ | async)"
                            class="ft2-btn-primary -large px-6">
                        Verify & save
                    </button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="stepsTypes.Edit">
                <div class="ft2-edit-dialog_header">
                    <div class="ft2-edit-dialog_heading h-center text-center">
                        Two-factor Authentication is enabled
                        for your account.
                    </div>
                </div>
                <div class="ft2-auth-actions">
                    <div class="ft2-auth-actions_button text-center"
                         (click)="onGenerateRecoveryCodes()">
                        <span class="ft2-auth-actions_title">Lost your recovery codes?</span>
                        <p class="ft-heading ft-text-plus-2 bold-text">Generate new codes</p>
                    </div>
                    <div class="ft2-auth-actions_button text-center"
                         (click)="onResetTwoFactorAuth()">
                        <span class="ft2-auth-actions_title">Have a new device or app?</span>
                        <p class="ft-heading ft-text-plus-2 bold-text">Set up a new key</p>
                        <span class="medium-text">This will reset your current setup</span>
                    </div>
                </div>
                <div class="h-center mt-5">
                    <ft-confirm-modal title="Are you sure you want to disable Two-factor Authentication?"
                                      description="This will remove the extra level of security on your account."
                                      confirmLabel="Turn off 2FA"
                                      (confirm)="onTurnOffTwoFactorAuth()">
                        <button class="ft2-btn-light -outline -huge">Turn off 2FA</button>
                    </ft-confirm-modal>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="stepsTypes.RecoveryCodes">
                <div class="ft2-edit-dialog_header">
                    <div class="ft2-edit-dialog_heading">
                        Recovery codes
                    </div>
                    <div class="ft2-edit-dialog_summary">
                        Recovery codes provide a way to sign in if you lose access to your authenticator app (or don’t have it with you).
                        Save these and keep them somewhere safe.
                    </div>
                </div>
                <ft-loading [loading$]="loading$">
                </ft-loading>
                <div class="ft2-edit-dialog_modal"
                     *ngIf="recoveryCodes?.length">
                    <div class="ft2-recovery-codes">
                        <p *ngFor="let code of recoveryCodes; let last = last" class="text-center">{{ code }}</p>
                    </div>
                </div>
                <div class="ft2-edit-dialog_footer">
                    <button (click)="closeRecoveryCodesView()"
                            class="ft2-btn-primary -large px-6">
                        Done
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ft-modal2>
