import { Component, OnInit } from '@angular/core';
import { SignInDto } from '../../models/sign-in-dto';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SignInStates } from '../../models/sign-in-states.enum';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import { selectTwoFactorAuthRedirectTrigger } from 'account-hybrid/features/authentication/store/authentication.selectors';
import {
    setSignInRedirectTo,
    setSignInState,
    signIn,
} from 'account-hybrid/features/authentication/store/authentication/authentication.actions';
import { skip } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StateService } from '@uirouter/core';
import { SignInBaseComponent } from 'account-hybrid/features/authentication/pages/sign-in-base/sign-in-base.component';

@UntilDestroy()
@Component({
    selector: 'ft-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends SignInBaseComponent implements OnInit {
    twoFactorAuthRedirectTrigger$: Observable<boolean>;

    constructor(
        public store: Store<AuthenticationState>,
        private stateService: StateService
    ) {
        super(store);

        // workaround for 2FA redirect, state manager not available in effects
        this.twoFactorAuthRedirectTrigger$ = this.store.pipe(
            select(selectTwoFactorAuthRedirectTrigger),
            skip(1)
        );
        this.twoFactorAuthRedirectTrigger$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.stateService.go('2fa'));
    }

    signIn(formValue: SignInDto) {
        this.store.dispatch(signIn({ data: formValue }));
    }

    changeState(state: SignInStates) {
        this.store.dispatch(setSignInState({ signInState: state }));
    }
}
