<div class="ft2-tree-node" [class]="modifierClass">
    <button *ngIf="!isCollapsed && removable" class="ft2-btn-text ft2-tree-node_remove-btn" (click)="remove.next()">
        Remove
    </button>
    <div class="ft2-tree-node_title" (click)="isCollapsed = !isCollapsed">
        <mat-icon class="mr-2 icon-lg">{{!isCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
        <ng-content></ng-content>
    </div>
    <div [hidden]="isCollapsed" [@slideDown]="!isCollapsed" class="ft2-tree-node_details">
        <ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
    </div>
</div>
<div [hidden]="isCollapsed" [@slideDown]="!isCollapsed" class="ft2-tree-node_children">
    <ng-container *ngTemplateOutlet="childrenTemplate"></ng-container>
</div>
