import { Component } from '@angular/core';
import * as fromAuthentication from '../../store';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ForgotPasswordStates } from '../../models/forgot-password-states.enum';
import { AuthError } from "../../models/auth-error";
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import {
    getForgotPasswordError,
    getForgotPasswordSentNew,
    getForgotPasswordState,
    isForgotPasswordLoading,
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import {
    sendForgotPasswordEmail,
    setForgotPasswordState,
} from 'account-hybrid/features/authentication/store/authentication/authentication.actions';

@Component({
    selector: 'ft-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    loading$: Observable<boolean>;
    state$: Observable<ForgotPasswordStates>;
    error$: Observable<AuthError>;
    sentNew$: Observable<boolean>;
    forgotPasswordStates = ForgotPasswordStates;
    userEmail: string;

    constructor(private store: Store<AuthenticationState>) {
        this.loading$ = this.store.pipe(select(isForgotPasswordLoading));
        this.state$ = this.store.pipe(select(getForgotPasswordState));
        this.error$ = this.store.pipe(select(getForgotPasswordError));
        this.sentNew$ = this.store.pipe(select(getForgotPasswordSentNew));
    }

    sendForgotPasswordEmail() {
        this.store.dispatch(sendForgotPasswordEmail({data: {email: this.userEmail}}));
    }

    changeState(state: ForgotPasswordStates) {
        this.store.dispatch(setForgotPasswordState({forgotPasswordState: state}));
    }
}
