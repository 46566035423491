import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'ftPlaceholder'
})
export class PlaceholderPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: any, text: string = 'Click to edit'): SafeHtml {
        if (typeof value === 'undefined' || value === null || value === '' || value?.length === 0) {
            return this.sanitizer.bypassSecurityTrustHtml(`<span class="muted-text">${text}</span>`)
        }
        return value;
    }
}
