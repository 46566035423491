import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { takeWhile } from "rxjs/operators";
import { AuthError } from "../../models/auth-error";
import { UserData } from '../../models/user-data';
import {
    changeUser,
    changeUserFailure,
    changeUserSuccess,
    enterSite,
    resendUser,
    resetPassword,
    resetPasswordSuccess,
    verifyToken,
} from '../../store/authentication/authentication.actions';
import {
    getChangeUserError,
    getResendUserError, getResetPasswordError,
    getTokenVerificationError,
    getUserData, isChangeUserLoading,
    isResendUserLoading, isResetPasswordLoading,
    isTokenVerificationLoading, isUserResent
} from 'account-hybrid/features/authentication/store/authentication.selectors';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';

@Component({
    selector: 'ft-create-password',
    templateUrl: './create-password.component.html',
    styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
    @Input() token: string;
    @Input() userUuid: string;
    @Input() companyCode: string;
    tokenVerificationLoading$: Observable<boolean>;
    tokenVerificationError$: Observable<AuthError>;
    userData$: Observable<UserData>;
    resendUserError$: Observable<AuthError>;
    resendUserLoading$: Observable<boolean>;
    isUserResent$: Observable<boolean>;
    createPasswordLoading$: Observable<boolean>;
    createPasswordError$: Observable<AuthError>;
    changeUserLoading$: Observable<boolean>;
    changeUserError$: Observable<AuthError>;
    password: string;
    isAlive = true;


    constructor(private store: Store<AuthenticationState>,
                private actions$: Actions) {
        this.tokenVerificationLoading$ = this.store.pipe(select(isTokenVerificationLoading));
        this.tokenVerificationError$ = this.store.pipe(select(getTokenVerificationError));
        this.userData$ = this.store.pipe(select(getUserData));
        this.resendUserLoading$ = this.store.pipe(select(isResendUserLoading));
        this.resendUserError$ = this.store.pipe(select(getResendUserError));
        this.isUserResent$ = this.store.pipe(select(isUserResent));
        this.createPasswordLoading$ = this.store.pipe(select(isResetPasswordLoading));
        this.createPasswordError$ = this.store.pipe(select(getResetPasswordError));
        this.changeUserLoading$ = this.store.pipe(select(isChangeUserLoading));
        this.changeUserError$ = this.store.pipe(select(getChangeUserError));
    }

    ngOnInit() {
        this.verifyToken();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    submit(formValue) {
        this.changePassword();
        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(resetPasswordSuccess),
        ).subscribe(() => {
            this.changeUser(formValue);
        });
    }

    changeUser({firstName, lastName}) {
        this.store.dispatch(changeUser({
                data: {
                    firstName,
                    lastName
                }
            })
        );
        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(changeUserSuccess, changeUserFailure),
        ).subscribe(() => {
            this.store.dispatch(enterSite());
        });
    }

    changePassword() {
        this.store.dispatch(resetPassword({
                data: {
                    password: this.password,
                    token: this.token,
                    userUuid: this.userUuid
                }
            })
        );
    }

    verifyToken() {
        this.store.dispatch(verifyToken({
            userUuid: this.userUuid,
            token: this.token
        }));
    }

    requestLink() {
        this.store.dispatch(resendUser({
            userUuid: this.userUuid,
            companyCode: this.companyCode
        }));
    }

}
