import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FilePathPipe } from '../../pipes/file-path.pipe';
import { GeopatternBackgroundDirective } from '../../directives/geopattern/geopattern-background.directive';
import { GeopatternBackgroundPipe } from '../../pipes/geopattern.pipe';
import { CheckLicenseDirective } from '../../directives/check-license.directive';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { FliptoToggleComponent } from '../../components/flipto-toggle/flipto-toggle.component';
import { AzureImageUrlPipe } from '../../pipes/azure-image-url.pipe';
import { CreateAccountComponent } from '../account-management/components/create-account/create-account.component';
import { OnOffTogglePipe } from 'account-hybrid/common/pipes/on-off-toggle.pipe';
import { FaceHighlightersDirective } from '../../directives/face-highlighters.directive';
import { LanguageDataPipe } from 'account-hybrid/common/pipes/language-data.pipe';
import { SplitPipe } from 'account-hybrid/common/pipes/split.pipe';
import { AbsNumberPipe } from 'account-hybrid/common/pipes/abs-number.pipe';
import { DatesDurationPipe } from 'account-hybrid/common/pipes/dates-duration.pipe';
import { AbExperimentRelevancePipe } from 'account-hybrid/common/pipes/ab-experiment-relevance.pipe';
import { DateIsBeforePipe } from 'account-hybrid/common/pipes/date-is-before.pipe';
import { SortPipe } from 'account-hybrid/common/pipes/sort.pipe';
import { SumPipe } from 'account-hybrid/common/pipes/sum.pipe';
import { ShortNumberPipe } from 'account-hybrid/common/pipes/short-number.pipe';
import { HandlerPipe } from 'account-hybrid/common/pipes/handler.pipe';


@NgModule({
    declarations: [
        FilePathPipe,
        AzureImageUrlPipe,
        GeopatternBackgroundDirective,
        GeopatternBackgroundPipe,
        OnOffTogglePipe,
        CheckLicenseDirective,
        FaceHighlightersDirective,
        FliptoToggleComponent,
        LanguageDataPipe,
        SplitPipe,
        AbsNumberPipe,
        DateIsBeforePipe,
        DatesDurationPipe,
        AbExperimentRelevancePipe,
        SortPipe,
        SumPipe,
        ShortNumberPipe,
        HandlerPipe
    ],
    imports: [
        CommonModule,
        AccountSharedModule
    ],
    exports: [
        FilePathPipe,
        AzureImageUrlPipe,
        OnOffTogglePipe,
        GeopatternBackgroundDirective,
        GeopatternBackgroundPipe,
        CheckLicenseDirective,
        FliptoToggleComponent,
        FaceHighlightersDirective,
        LanguageDataPipe,
        SplitPipe,
        AbsNumberPipe,
        DateIsBeforePipe,
        DatesDurationPipe,
        AbExperimentRelevancePipe,
        SortPipe,
        SumPipe,
        ShortNumberPipe,
        HandlerPipe
    ],
    providers: [
        DecimalPipe,
    ]
})
export class CommonDeclarationsModule {
}
