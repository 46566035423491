<ft-authentication-wrapper>
    <ng-container [ngSwitch]="(state$ | async)">
        <div class="ft2-auth-container" *ngSwitchDefault>
            <form #form="ngForm" (ngSubmit)="signIn(form.value)">
                <p class="text-center">
                    Enter the code from your authenticator
                    app to continue signing in.
                </p>
                <div class="ft2-form-error" *ngIf="(error$ | async) as error">
                    <ng-container *ngIf="(error.message === signInErrorMessages.invalidCredentials) || (error.message === signInErrorMessages.sessionExpired); else otherError">
                        <ng-container *ngIf="error.message === signInErrorMessages.invalidCredentials">
                            Invalid code. Please try again.
                        </ng-container>
                        <ng-container *ngIf="error.message === signInErrorMessages.sessionExpired">
                            Your session expired. <br>
                            <a class="ft2-link -underline" uiSref="sign-in">Back to login</a>
                        </ng-container>
                    </ng-container>

                    <ng-template #otherError>
                        There was an error. Please try again later.
                    </ng-template>
                </div>
                <input class="ft2-input"
                       ftAutofocus
                       type="text"
                       placeholder="Code"
                       autocomplete="off"
                       name="otpCode"
                       required
                       ngModel>
                <div class="mt-2">
                    <button [disabled]="form.invalid"
                            type="submit"
                            class="ft2-btn-primary -large -wide"
                            [ftLoading]="loading$">
                        Verify
                    </button>
                </div>
                <label class="ft2-checkbox mt-2 v-center h-center"
                       for="rememberDevice">
                    <input id="rememberDevice"
                           name="rememberDevice"
                           type="checkbox"
                           [ngModel]="false">
                    <span class="ft2-checkbox_control mt-0"></span>
                    <span class="bold-text">Remember this browser</span>
                </label>
                <div class="mt-5">
                    <a class="ft2-link -underline small-text" uiSref="2fa/recovery">Use recovery code</a>
                </div>
            </form>
        </div>
        <div class="ft2-auth-container" *ngSwitchCase="signInStates.Disabled">
            <div class="text-content">
                <h1 class="h5">Your account is disabled</h1>
                <p>Please contact
                    <a href="emailto:support@flip.to">support@flip.to</a>
                    if you need to restore your account.
                </p>
            </div>
            <div class="mt-3">
                <a class="ft2-link -underline" (click)="changeState(signInStates.Default)">
                    <span class="medium-text">
                        Return to Sign in
                    </span>
                </a>
            </div>
        </div>
        <div class="ft2-auth-container" *ngSwitchCase="signInStates.Locked">
            <div class="text-content">
                <h1 class="h5">Too many attempts!</h1>
                <p>Please try again in 5 minutes.</p>
            </div>
            <div class="mt-3">
                <a class="ft2-link -underline" (click)="changeState(signInStates.Default)">
                    <span class="medium-text">
                        Return to Sign in
                    </span>
                </a>
            </div>
        </div>
    </ng-container>
</ft-authentication-wrapper>
