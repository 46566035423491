import { createAction, props } from '@ngrx/store';
import { SignInDto } from "../../models/sign-in-dto";
import { User } from "../../../../core/authentication/models/user";
import { SignInStates } from "../../models/sign-in-states.enum";
import { SignInError } from "../../models/sign-in-error";
import { ForgotPasswordStates } from '../../models/forgot-password-states.enum';
import { ResetPasswordDto } from "../../models/reset-password-dto";
import { AuthError } from "../../models/auth-error";
import { ForgotPasswordDto } from "../../models/forgot-password-dto";
import { UserData } from "../../models/user-data";
import { ChangePasswordDto } from "../../models/change-password-dto";
import {
    TwoFactorAuthSetupResponse
} from '../../models/two-factor-auth-setup-response';
import { TwoFactorAuthSignInDto } from 'account-hybrid/features/authentication/models/two-factor-auth-sign-in-dto';
import { TwoFactorRecoveryDto } from 'account-hybrid/features/authentication/models/two-factor-recovery-dto';

// sign in
export const signIn = createAction(
    '[Authentication] SignIn',
    props<{ data: SignInDto }>()
);

export const signInFailure = createAction(
    '[Authentication] SignIn Failure',
    props<{ error: SignInError }>()
);

export const signInSuccess = createAction(
    '[Authentication] SignIn Success',
    props<{ userSession: User }>()
);

export const redirectToTwoFactorAuth = createAction(
    '[Authentication] Redirect To Two Factor Auth'
);

export const twoFactorAuthSignIn = createAction(
    '[Authentication] Two Factor Auth SignIn',
    props<{ data: TwoFactorAuthSignInDto }>()
);

export const twoFactorAuthSignInFailure = createAction(
    '[Authentication] Two Factor Auth SignIn Failure',
    props<{ error: SignInError }>()
);

export const twoFactorAuthSignInSuccess = createAction(
    '[Authentication] Two Factor Auth SignIn Success',
    props<{ userSession: User }>()
);

export const twoFactorAuthRecovery = createAction(
    '[Authentication] Two Factor Auth Recovery',
    props<{ data: TwoFactorRecoveryDto }>()
);

export const twoFactorAuthRecoveryFailure = createAction(
    '[Authentication] Two Factor Auth Recovery Failure',
    props<{ error: SignInError }>()
);

export const twoFactorAuthRecoverySuccess = createAction(
    '[Authentication] Two Factor Auth Recovery Success'
);

export const setSignInState = createAction(
    '[Authentication] Set SignIn State',
    props<{ signInState: SignInStates }>()
);

export const setSignInRedirectTo = createAction(
    '[Authentication] Set SignIn State',
    props<{ redirectTo: string }>()
);

export const sendForgotPasswordEmail = createAction(
    '[Authentication] Send ForgotPassword Email',
    props<{ data: Partial<ForgotPasswordDto> }>()
);

export const setForgotPasswordState = createAction(
    '[Authentication] Set ForgotPassword State',
    props<{ forgotPasswordState: ForgotPasswordStates }>()
);

export const sendForgotPasswordEmailSuccess = createAction(
    '[Authentication] Send ForgotPassword Email Success'
);

export const sendForgotPasswordEmailFailure = createAction(
    '[Authentication] Send ForgotPassword Email Failure',
    props<{ error: object }>()
);

// reset password
export const resetPassword = createAction(
    '[Authentication] Reset password',
    props<{ data: ResetPasswordDto }>()
);

export const resetPasswordSuccess = createAction(
    '[Authentication] Reset password Success',
    props<{ userSession: User }>()
);

export const resetPasswordFailure = createAction(
    '[Authentication] Reset password Failure',
    props<{ error: AuthError }>()
);

// user name
export const changeUser = createAction(
    '[Authentication] Create user',
    props<{ data: UserData }>()
);

export const changeUserSuccess = createAction(
    '[Authentication] Create user Success',
    props<{ userData: any }>()
);

export const changeUserFailure = createAction(
    '[Authentication] Create user Failure',
    props<{ error: any }>()
);

export const uploadProfilePicture = createAction(
    '[Authentication] Upload profile picture',
    props<{ file: File }>()
);

export const uploadProfilePictureSuccess = createAction(
    '[Authentication] Upload profile picture Success',
    props<{profileImageUrl: string}>()
);

export const uploadProfilePictureFailure = createAction(
    '[Authentication] Upload profile picture Failure',
    props<{ error: any }>()
);

// verify token
export const verifyToken = createAction(
    '[Authentication] Verify token',
    props<{ userUuid: string, token: string }>()
);

export const verifyTokenSuccess = createAction(
    '[Authentication] Verify token Success',
    props<{ userData: UserData }>()
);

export const verifyTokenFailure = createAction(
    '[Authentication] Verify token Failure',
    props<{ error: AuthError }>()
);

// resend new user
export const resendUser = createAction(
    '[Authentication] Resend new user',
    props<{ userUuid: string, companyCode: string }>()
);

export const resendUserSuccess = createAction(
    '[Authentication] Resend new user Success'
);

export const resendUserFailure = createAction(
    '[Authentication] Resend new user Failure',
    props<{ error: AuthError }>()
);

// redirect
export const enterSite = createAction(
    '[Authentication] Enter site'
);


// change password
export const changePassword = createAction(
    '[Authentication] Change password',
    props<{ data: ChangePasswordDto }>()
);

export const changePasswordSuccess = createAction(
    '[Authentication] Change password Success'
);

export const changePasswordFailure = createAction(
    '[Authentication] Change password Failure',
    props<{ error: any }>()
);
