<ft-authentication-wrapper>
    <ng-container [ngSwitch]="(state$ | async)">
        <div class="ft2-auth-container" *ngSwitchDefault>
            <form #form="ngForm" (ngSubmit)="signIn(form.value)">
                <div class="ft2-form-error" *ngIf="(error$ | async) as error">
                    <ng-container *ngIf="error.message === signInErrorMessages.invalidCredentials; else otherError">
                        Invalid email or password
                    </ng-container>
                    <ng-template #otherError>
                        There was an error. Please try again later.
                    </ng-template>
                </div>
                <input class="ft2-input"
                       ftAutofocus
                       type="text"
                       placeholder="Email"
                       name="email"
                       email
                       required
                       ngModel>
                <div class="ft2-input-wrapper">
                    <input class="ft2-input"
                           #input
                           type="password"
                           name="password"
                           placeholder="Password"
                           required
                           ngModel>
                    <ft-password-toggle class="ft2-input-icon" [inputRef]="input"></ft-password-toggle>
                </div>
                <div class="mt-2">
                    <button [disabled]="form.invalid"
                            type="submit"
                            class="ft2-btn-primary -large -wide"
                            [ftLoading]="loading$">
                        Sign in
                    </button>
                </div>
            </form>
            <div class="mt-3">
                <a class="ft2-link" [uiSref]="'recover-password'">
                    <span class="medium-text">
                        Forgot password
                    </span>
                </a>
            </div>
        </div>
        <div class="ft2-auth-container" *ngSwitchCase="signInStates.Disabled">
            <div class="text-content">
                <h1 class="h5">Your account is disabled</h1>
                <p>Please contact
                    <a href="emailto:support@flip.to">support@flip.to</a>
                    if you need to restore your account.
                </p>
            </div>
            <div class="mt-3">
                <a class="ft2-link -underline" (click)="changeState(signInStates.Default)">
                    <span class="medium-text">
                        Return to Sign in
                    </span>
                </a>
            </div>
        </div>
        <div class="ft2-auth-container" *ngSwitchCase="signInStates.Locked">
            <div class="text-content">
                <h1 class="h5">Too many attempts!</h1>
                <p>Please try again in 5 minutes.</p>
            </div>
            <div class="mt-3">
                <a class="ft2-link -underline" (click)="changeState(signInStates.Default)">
                    <span class="medium-text">
                        Return to Sign in
                    </span>
                </a>
            </div>
        </div>
    </ng-container>
</ft-authentication-wrapper>
