<ft-modal-wrapper>
  <ft-touchpoint-header [name]="'Re-engagement Emails - Global scope'" [activeToggleEnabled]='false'>
  </ft-touchpoint-header>
  <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms">
    <mat-tab label="Styling">
      <ng-template matTabContent>
        <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
          <ng-container *ngIf="reengagementEmail$ | async | clone as data">
            <ft-snapshot-form (commit)="commit({reengagementEmailViewModel: $event})" [loading$]="isLoading$" [commitSuccess$]="commitSuccess$">
              <section class="ft2-block -small">
                <div class="ft2-container">
                  <form ngForm>
                    <ft-select-editor [title]="'Email Logo'"
                                      [name]="'logoLayoutType'"
                                      [options]="logoLayoutTypeOptions"
                                      [(ngModel)]="data.logoLayoutType">
                      <ft-edit-panel label="Email Logo" [loading]="isLoading$ | async">
                        <ng-container
                          *ngFor="let item of logoLayoutTypeOptions">
                          <div *ngIf="item.value === data.logoLayoutType" [innerHTML]="item.label | ftPlaceholder">
                          </div>
                        </ng-container>
                      </ft-edit-panel>
                    </ft-select-editor>
                  </form>
                </div>
              </section>
            </ft-snapshot-form>
          </ng-container>
        </ft-permissions>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
