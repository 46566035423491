<ng-template #modalTmpl>
    <div class="ft2-edit-dialog"
         [hidden]="!customTmpl?.hasChildNodes()"
         #customTmpl>
        <ng-content select="[customTemplate]"></ng-content>
    </div>
    <button class="ft2-btn-close -fixed"
            (click)="close()"></button>
    <ng-container *ngIf="!customTmpl?.hasChildNodes()">
        <div class="ft2-edit-dialog">
            <div class="ft2-edit-dialog_body">
                <div *ngIf="title || summary"
                     class="ft2-edit-dialog_header">
                    <div *ngIf="title"
                         class="ft2-edit-dialog_heading">
                        {{ title }}
                    </div>
                    <div *ngIf="summary"
                         class="ft2-edit-dialog_summary">
                        {{ summary }}
                    </div>
                </div>
                <ng-content></ng-content>
                <div class="ft2-edit-dialog_footer">
                    <button type="submit"
                            (click)="onDone()"
                            class="ft2-btn-primary -large px-6">
                        Done
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
<div [hidden]="!triggerTmpl?.hasChildNodes()"
     #triggerTmpl
     (click)="open()">
    <ng-content select="[trigger]"></ng-content>
</div>
