import { License, LicenseType } from './licenses';


export interface User {
    Id: string;
    FirstName: string;
    LastName: string;
    Email: string;
    IsForceChangePassword: boolean;
    ProfileImageUrl: string;
    UserType: UserType;
    Companies: Company[];
    Properties: Property[];
    IsImpersonating: boolean;
    Is2FAEnabled: boolean;
}

export enum UserType {
    Anonymous = 0,
    Admin = 1,
    User = 2
}

export enum CompanyType {
    Unknown = 0,
    Group = 1,
    Cooperative = 2, // @deprecated? TODO discuss
    Collaborative = 3
}

export interface Company {
    Code: string;
    UUID: string;
    Name: string;
    Type: CompanyType;
    BackgroundImageUrl: string;
    DarkBackgroundLogoUrl: string;
    LightBackgroundLogoUrl: string;
    Licenses: LicenseType[];
    LicensesExtended: License[];
    Permissions: number;
    FeatureFlags: { [key: string]: boolean };
    Associations: string[];
    Settings: CollaborativeCompanySettings | null;
    ActiveAssociatedPropertiesCount: number;
}

export interface CollaborativeCompanySettings {
    CollaborativeTypeId: number;
    SponsorProperty: Property;
    DestinationDisplayName: string;
    DefaultAttributionDelay: number;
    SecondaryAttributionDelay: number;
    TertiaryAttributionDelay: number;
    InsightSegments: CollaborativeCompanyInsightSegment[];
    Description: string;
}

export interface CollaborativeCompanyInsightSegment {
    Name: string;
    Term: string;
    Order: number;
    MatchedProperties: number;
}

export enum PropertyType {
    None = 0,
    DMO = 1,
    FullServiceHotel = 2,
    SelectServiceHotel = 4,
    Condotel = 8,
    VacationRental = 16,
    Attraction = 32,
    Restaurant = 64,
    Golf = 128,
    ParentCompany = 256,
    RVResort = 512,
    VacationRentalGroup = 1024,
    DecentralizedProperty = 2048,
    CruiseShip = 4096
}

export interface Property {
    UUID: string;
    Name: string;
    DisplayName: string;
    Slug: string;
    BackgroundImageUrl: string;
    LightBackgroundLogoUrl: string;
    DarkBackgroundLogoUrl: string;
    IsTrackingEnabled: boolean;
    IsDemographicsEnabled: boolean;
    IsWebsiteIntegrated: boolean;
    IsBookingEngineIntegrated: boolean;
    PropertyUrl: string;
    Permissions: number;
    Licenses: LicenseType[];
    LicensesExtended: License[];
    PropertyType?: PropertyType;
    Rooms: number;
    Adr: number;
    IsVirtual?: boolean;
}
