import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    selectDiscoveryPropertySettingsCommitSuccess,
    selectDiscoveryPropertySettingsError,
    selectDiscoveryPropertySettingsIntegration,
    selectDiscoveryPropertySettingsLoading,
    selectDiscoveryPropertySettingsMessaging,
    selectDiscoveryPropertySettingsStyling,
    selectIsFlightBookingOptionActive
} from 'account-hybrid/common/components/touchpoints/store';
import { combineLatest, Observable, of } from 'rxjs';
import {
    loadDiscoveryPropertySettings,
    saveDiscoveryPropertySettings
} from 'account-hybrid/common/components/touchpoints/store/property-settings/property-settings.actions';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import {
    DiscoveryPropertyIntegrationDto,
    DiscoveryPropertyMessagingDto,
    DiscoveryPropertySettingsDto,
    DiscoveryPropertyStylingDto
} from 'libs/api/data-contracts';
import { map, take } from 'rxjs/operators';
import { Language } from '../../../../models/language.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PropertyUserDataService } from 'account-hybrid/features/mission-control/services/property-user-data.service';
import {
    PropertyUser,
    PropertyUserDtoExtensions
} from 'account-hybrid/features/mission-control/models';
import { EditorToken } from 'apps/account/src/account-shared';
import { Transition } from '@uirouter/core';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import {
    selectDiscoveryThemingOptions,
    setCurrentDisplayedDiscoveryVersion
} from 'account-hybrid/features/discovery/store';
import { DiscoveryThemingOption } from 'account-hybrid/features/discovery/models/discovery-theming-option.model';
import {
    mapLanguageNameToCode
} from 'account-hybrid/common/modules/account-management/utils/language-name-mapping.util';
import { LicenseType, SessionService } from 'account-hybrid/core/authentication';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';
import * as _ from 'lodash';
import { DiscoveryPropertySettingState, Guid } from 'shared';

@UntilDestroy()
@Component({
    selector: 'ft-planner-flow',
    templateUrl: './planner-flow.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PlannerFlowComponent extends MultiLanguageComponent implements OnInit {
    stylingViewModel$: Observable<DiscoveryPropertyStylingDto>;
    integration$: Observable<DiscoveryPropertyIntegrationDto>;
    messaging$: Observable<DiscoveryPropertyMessagingDto[]>;
    messaging: DiscoveryPropertyMessagingDto[];
    languages$: Observable<Language[]>;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    isFlightBookingOptionActive$: Observable<boolean>;
    isExperiencesLicenseActive: boolean;
    error$: Observable<any>;
    selectedIndex = 0;
    users$: Observable<PropertyUser[]>;
    messageTokens: EditorToken[] = [
        {
            name: 'Org name',
            value: '{Org-name}'
        },
        {
            name: 'City name',
            value: '{City-name}'
        },
        {
            name: 'Arrival month',
            value: '{Arrival-month}'
        },
        {
            name: 'Arrival season',
            value: '{Arrival-season}'
        }
    ];
    consentMessageTokens: EditorToken[] = [
        {
            name: 'Org name',
            value: '{Org-name}'
        },
    ];
    plannerFlowThemeOptions$: Observable<DiscoveryThemingOption[]>;
    displayedVersion: DiscoveryVersions;
    discoveryVersionsEnum = DiscoveryVersions;
    discoveryPropertySettingOptions = [
        { label: 'Off', value: DiscoveryPropertySettingState.Disabled },
        { label: 'Off, preview mode', value: DiscoveryPropertySettingState.Preview },
        { label: 'On', value: DiscoveryPropertySettingState.Published }
    ];

    constructor(
        store: Store<any>,
        private spacesService: SpacesService,
        private sessionService: SessionService,
        private transition: Transition,
        private propertyUserDataService: PropertyUserDataService) {
        super(store);
    }

    ngOnInit() {
        this.displayedVersion = this.transition.params().version;
        const property = this.sessionService.getProperty(this.spacesService.getPropertyUuid());
        this.isExperiencesLicenseActive = !!property?.LicensesExtended?.find(license => license.LicenseId === LicenseType.Experiences);
        if (this.displayedVersion) {
            this.store.dispatch(setCurrentDisplayedDiscoveryVersion({ version: this.displayedVersion }));
        }
        this.store.dispatch(loadDiscoveryPropertySettings({ propertyUuid: this.spacesService.getPropertyUuid() }));
        this.propertyUserDataService.getAll();
        this.plannerFlowThemeOptions$ = this.store.pipe(select(selectDiscoveryThemingOptions));
        this.stylingViewModel$ = this.store.pipe(select(selectDiscoveryPropertySettingsStyling));
        this.isFlightBookingOptionActive$ = this.store.pipe(select(selectIsFlightBookingOptionActive));
        this.integration$ = this.store.pipe(select(selectDiscoveryPropertySettingsIntegration)).pipe(
            map((val) => {
                if (val && val.signatureCompanyUserUuid == null) {
                    val.signatureCompanyUserUuid = Guid.Empty;
                }

                return val;
            }));

        this.users$ = combineLatest([this.integration$, this.propertyUserDataService.entities$])
            .pipe(
                map(([integration, users]) => {
                    let signatureUser: string;
                    if (integration?.inheritedSignatureCompanyUserUuid) {
                        const inheritedUser = users.find(u => PropertyUserDtoExtensions.getPropertyUserDtoKey(u) === integration.inheritedSignatureCompanyUserUuid.toLowerCase());
                        signatureUser = inheritedUser.FullName;
                    } else {
                        signatureUser = `"Team at ${this.spacesService.current.property?.title || this.spacesService.current?.title}"`;
                    }

                    return [new PropertyUser({
                        Id: Guid.Empty,
                        CompanyUserUuid: Guid.Empty,
                        FullName: `Inherit from Mission Control (${signatureUser})`
                    }), ...(_.sortBy(users.filter(user => user.First || user.Last), 'FullName'))];
                })
            );
        this.messaging$ = combineLatest([
            this.store.pipe(select(selectDiscoveryPropertySettingsMessaging)),
            this.languages$
        ])
            .pipe(
                map(([{ messaging }, availableLanguages]) => {
                    if (!Array.isArray(messaging) || !messaging?.length) {
                        const defaultData = [
                            ...availableLanguages.map(({ languageCode, languageName }) => ({
                                languageCode,
                                languageName,
                                headcountStepDisclosure: null,
                                signatureText: null,
                                introGreetingText: null,
                                brandWelcomeText: null,
                                timelineRequirementsCustomMessage: null
                            }))
                        ];
                        this.messaging = mapLanguageNameToCode(defaultData, availableLanguages);
                        return this.messaging;
                    }
                    this.messaging = mapLanguageNameToCode(messaging, availableLanguages);
                    return this.messaging;
                }),
                untilDestroyed(this)
            );
        this.messaging$.subscribe();
        this.isLoading$ = this.store.pipe(select(selectDiscoveryPropertySettingsLoading));
        this.commitSuccess$ = this.store.pipe(select(selectDiscoveryPropertySettingsCommitSuccess));
        this.error$ = this.store.pipe(select(selectDiscoveryPropertySettingsError));
    }

    commit(settings: Partial<DiscoveryPropertySettingsDto>) {
        this.store.dispatch(saveDiscoveryPropertySettings({ settings }));
    }

    commitMessagingTab(editedMessages: Partial<{ [key: string]: DiscoveryPropertyMessagingDto[] | DiscoveryPropertyIntegrationDto }>) {
        combineLatest([
            this.integration$
                .pipe(take(1)),
            this.messaging$
                .pipe(take(1)),
        ])
            .subscribe(([integration, messaging]) => {
                let updatedMessagingData = [...messaging];
                let updatedIntegrationData = {...integration};
                Object.entries(editedMessages)
                    .forEach(([key, value]) => {
                        if (Array.isArray(value) && Object.keys(messaging[0]).includes(key)) {
                            updatedMessagingData = updatedMessagingData.map(message => ({
                                ...message,
                                [key]: value?.find(item => message.languageCode === item.languageCode)?.[key]
                            }));
                        } else if (Object.keys(updatedIntegrationData).includes(key)) {
                            updatedIntegrationData = {
                                ...updatedIntegrationData,
                                [key]: value,
                            };
                        }
                    });
                this.store.dispatch(saveDiscoveryPropertySettings({ settings: { messaging: updatedMessagingData, integration: updatedIntegrationData } }));
            });
    }

    isCommunicationUserSelected(data: DiscoveryPropertyIntegrationDto) {
        return data?.signatureCompanyUserUuid && data.signatureCompanyUserUuid !== Guid.Empty;
    }
}
