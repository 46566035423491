import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SignInStates } from '../../models/sign-in-states.enum';
import { SignInError } from '../../models/sign-in-error';
import { SignInErrorMessages } from '../../models/sign-in-error-messages.enum';
import { AuthenticationState } from 'account-hybrid/features/authentication/store/authentication/authentication.state';
import {
    setSignInState,
    twoFactorAuthRecovery,
} from 'account-hybrid/features/authentication/store/authentication/authentication.actions';
import { TwoFactorRecoveryDto } from 'account-hybrid/features/authentication/models/two-factor-recovery-dto';
import { SignInBaseComponent } from 'account-hybrid/features/authentication/pages/sign-in-base/sign-in-base.component';

@Component({
    selector: 'ft-two-factor-auth-recovery',
    templateUrl: './two-factor-auth-recovery.component.html',
    styleUrls: ['./two-factor-auth-recovery.component.scss'],
})
export class TwoFactorAuthRecoveryComponent
    extends SignInBaseComponent
    implements OnInit
{
    @Input() redirectTo: string;
    loading$: Observable<boolean>;
    error$: Observable<SignInError>;
    state$: Observable<SignInStates>;
    signInStates = SignInStates;
    signInErrorMessages = SignInErrorMessages;

    constructor(public store: Store<AuthenticationState>) {
        super(store);
    }

    signIn(formValue: TwoFactorRecoveryDto) {
        this.store.dispatch(twoFactorAuthRecovery({ data: formValue }));
    }

    changeState(state: SignInStates) {
        this.store.dispatch(setSignInState({ signInState: state }));
    }
}
