import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppAuthState } from './app-auth.state';
import { APP_AUTH_FEATURE_NAME } from './app-auth-feature-name.const';
import { TWO_FACTOR_AUTH_SETUP_FEATURE_NAME } from './two-factor-auth-setup/two-factor-auth-setup-feature-name.const';
import { TwoFactorAuthSetupState } from './two-factor-auth-setup/two-factor-auth-setup.state';

export const getAppAuthFeatureState = createFeatureSelector<AppAuthState>(
    APP_AUTH_FEATURE_NAME
);

export const getTwoFactorAuthState = createSelector(
    getAppAuthFeatureState,
    state => state[TWO_FACTOR_AUTH_SETUP_FEATURE_NAME]
);

export const selectTwoFactorAuthSetupData = createSelector(
    getTwoFactorAuthState,
    (state: TwoFactorAuthSetupState) => state.setup
)

export const selectTwoFactorAuthSetupIsLoading = createSelector(
    getTwoFactorAuthState,
    (state: TwoFactorAuthSetupState) => state.loading
)

export const selectTwoFactorAuthSetupError = createSelector(
    getTwoFactorAuthState,
    (state: TwoFactorAuthSetupState) => state.error
)

export const selectTwoFactorAuthRecoveryCodes = createSelector(
    getTwoFactorAuthState,
    (state: TwoFactorAuthSetupState) => state.recoveryCodes
)
