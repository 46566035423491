<ft-authentication-wrapper>
    <ng-container [ngSwitch]="(state$ | async)">
        <div class="ft2-auth-container" *ngSwitchDefault>
            <form #form="ngForm" (ngSubmit)="sendForgotPasswordEmail()">
                <div class="ft2-form-error" *ngIf="(error$ | async) as error">
                    <ng-container *ngIf="error.message === 'INVALID_EMAIL_FORMAT'; else otherError">
                        This email is invalid.
                    </ng-container>
                    <ng-template #otherError>
                        {{error.message || 'There was an error. Please try again later.'}}
                    </ng-template>
                </div>
                <p>Let's set you up with a new password. Enter the email you use to sign in.</p>
                <input class="ft2-input" type="email" placeholder="Email" email name="email" required
                       [(ngModel)]="userEmail"/>
                <div class="mt-2">
                    <button [disabled]="form.invalid" class="ft2-btn-primary -large -wide" type="submit"
                            [ftLoading]="loading$">
                        Get reset link
                    </button>
                </div>
            </form>
            <div class="mt-3">
                <a class="ft2-link" [uiSref]="'sign-in'">
                    <span class="medium-text">
                        Cancel
                    </span>
                </a>
            </div>
        </div>
        <div class="ft2-auth-container" *ngSwitchCase="forgotPasswordStates.EmailSent">
            <div class="ft2-form-error" *ngIf="(error$ | async) as error">
                {{error.message || 'There was an error. Please try again later.'}}
            </div>
            <p class="mb-4">Thanks. We just sent a reset link to {{userEmail}}.</p>
            <p>
                Didn't receive an email?
                <br>
                <a class="ft2-link" (click)="changeState(forgotPasswordStates.Default)">
                    <span class="medium-text">
                        Correct your email address
                    </span>
                </a>
                <br>
                <a class="ft2-link" (click)="sendForgotPasswordEmail()" *ngIf="!(sentNew$ | async)"
                   [ftLoading]="loading$">
                    <span class="medium-text">
                        Send a new link
                    </span>
                </a>
            </p>
            <span class="medium-text" *ngIf="(sentNew$ | async)">
                Your link has been sent.
            </span>
            <br/>
        </div>
    </ng-container>
</ft-authentication-wrapper>
